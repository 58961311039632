import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistTracksToImport,
  deletePlaylistTrackToImport
} from 'store/actions/playlistAction'

import { tableSorter } from 'helpers/TableHelper'

import PlaylistTracksToImportTableRowActions from './playlist-tracks/PlaylistTracksToImportTableRowActions'

import CSVDownload from 'components/CSVDownload'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'

const classname = 'playlistTracksToImport'

class PlaylistTracksToImport extends Component {
  constructor(props){
    super(props)
    this.state = {
      sorter:{
        field:'title',
        direction:'asc'
      }
    }
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistTracksToImport(this.props.id))
  }

  sortTable(head){
    this.setState({
      sorter:head
    })
  }

  sortType(x,y){
    const {
      field,
      direction
    } = this.state.sorter

    return tableSorter(x,y,field,direction)
  }

  getTracks(){
    const {
      importerTracks
    } = this.props

    return importerTracks.sort((x,y) => this.sortType(x,y))
  }

  getCSVData() {
    const {
      importerTracks
    } = this.props

    let csvDataArray = []


    importerTracks.map((track) => {
      // escape double quotes in the csv file
      const trackData = {
        title: track.title.replace(/"/g, '""'),
        artist: track.artist.replace(/"/g, '""'),
        album: track.album.replace(/"/g, '""')
      }

      csvDataArray.push(trackData)
      return false
    })

    return csvDataArray
  }

  render(){
    const {
      dispatch,
      deletePlaylistTrackToImportLoading,
      playlistName
    } = this.props

    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-tracks" height="100%" column>
          <div>
            <CSVDownload
              ctaText='Download spreadsheet'
              data={this.getCSVData()}
              fileName={`${playlistName}--tracks-to-import`}
              useButton
            />
          </div>
          <TableWrapper
            classname={classname}
            data={this.getTracks()}
            sortTable={(head)=>this.sortTable(head)}
            rowActions={(
              <PlaylistTracksToImportTableRowActions
                classname={classname}
                deletePlaylistTrackToImport={(track_id)=>dispatch(deletePlaylistTrackToImport(track_id))}
                deletePlaylistTrackToImportLoading={deletePlaylistTrackToImportLoading}
              />
            )}
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    importerTracks: store.playlist.importerTracks,
    deletePlaylistTrackToImportLoading: store.playlist.deletePlaylistTrackToImportLoading,
    loading: store.playlist.loading,
  }
}

export default connect(mapStateToProps)(PlaylistTracksToImport)
