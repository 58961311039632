import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistTracksSplit,
  filterPlaylistTracks,
  removeTrackFromPlaylist,
  removeTracksFromPlaylist
} from 'store/actions/playlistAction'
import { getTrackUrl } from 'store/actions/playerAction'
import { toggleOverlay } from 'store/actions/overlayAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import PlaylistTracksSplitTableAction from './playlist-tracks/PlaylistTracksSplitTableAction'
import PlaylistTracksRow from './playlist-tracks/PlaylistTracksRow'

import TableWrapper from 'ui/TableWrapper'
import PlaylistTracksTableRowActions from './playlist-tracks/PlaylistTracksTableRowActions'

import { debounce } from 'helpers/Debounce'
import { tableSorter } from 'helpers/TableHelper'

const classname = 'playlistTracksSplit'

class PlaylistTracksSplit extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:'',
      actionActive:false,
      sorter:{
        field:'added',
        direction:'desc'
      }
    }
    this.filterTracksDispatch = debounce(this.filterTracksDispatch,300)
    this.selectedTracks = []
  }

  componentDidMount(){
    this.setState({filter:''})
    this.props.dispatch(getPlaylistTracksSplit(this.props.id,this.props.designationType))
  }

  playTrack(id){
    this.props.dispatch(getTrackUrl(id))
  }

  filterTracks(e){
    const filter = e.target.value
    this.filterTracksDispatch(filter)
  }

  filterTracksDispatch(term){
    this.props.dispatch(filterPlaylistTracks(term))
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'playlistTracksMore'))
  }

  sortTable(head){
    this.setState({
      sorter:head
    })
  }

  sortType(x,y){
    const {field, direction} = this.state.sorter
    return tableSorter(x,y,field,direction)
  }

  getTracks(){
    //sort tracks by date added to playlist and filter
    const tracks = this.props.tracksSplit
      .sort((x,y) => this.sortType(x,y))
      .filter(track => {
        //set checked on data for clear
        track.checked = false
        if(track.filter.toLowerCase().includes(this.props.filter)){
          return track
        }
        return false
      })
    return tracks
  }

  removeTrack(data){
    this.props.dispatch(removeTrackFromPlaylist(data))
  }

  checkboxAction(e,data,index){
    if(e.nativeEvent.metaKey || e.nativeEvent.shiftKey){
      return
    }
    if(e.target.checked){
      data.index = index
      this.selectedTracks.push(data)
    } else {
      this.selectedTracks = this.selectedTracks.filter(track => track.id !== data.id)
    }
    if(this.selectedTracks.length > 0){
      this.setState({actionActive:true})
    } else {
      this.setState({actionActive:false})
    }
  }

  clickRow(e,data,isChecked,index){
    e.stopPropagation()
    //cmd + click
    if(e.metaKey){
      if(!isChecked){
        data.index = index
        this.selectedTracks.push(data)
      } else {
        this.selectedTracks = this.selectedTracks.filter(track => track.id !== data.id)
      }
      if(this.selectedTracks.length > 0){
        this.setState({actionActive:true})
      } else {
        this.setState({actionActive:false})
      }
    }
    if(e.shiftKey){
      if(this.selectedTracks.length === 1){
        for (let i = this.selectedTracks[0].index + 1; i <= index; i++) {
          this.selectedTracks.push(this.props.tracks[i])
        }
        this.setState({actionActive:true})
      }
    }
  }

  deselectTracks(){
    this.selectedTracks = []
    this.setState({actionActive:false})
  }

  getBreakdown(data){
    this.props.dispatch(toggleOverlay(true,data,'breakdown'))
  }

  removeTracks(){
    this.props.dispatch(removeTracksFromPlaylist(this.selectedTracks));
    this.selectedTracks = []
    this.setState({
      actionActive:false
    })
  }

  openPlaylistOverlay(){

  }

  changeDesignation(data){
    console.log(data.event.target.value)
  }

  favouriteTrack(data){
    console.log(data)
  }

  sortByDesignation(split){

  }

  getTable(){
    return <TableWrapper
      classname={classname}
      data={this.getTracks()}
      loading={this.props.loading}
      sortTable={(head)=>this.sortTable(head)}
      rowComp={
        <PlaylistTracksRow
          playTrack={(id)=>this.playTrack(id)}
          checkboxSelected={this.selectedTracks}
          checkboxAction={(e,data,index)=>this.checkboxAction(e,data,index)}
          clickRow={(e,data,isChecked,index)=>this.clickRow(e,data,isChecked,index)}
          />
      }
      rowActions={<PlaylistTracksTableRowActions
        favouriteTrack={(data)=>this.favouriteTrack(data)}
        moreActions={(data)=>this.moreActions(data)}
        removeTrack={(data)=>this.removeTrack(data)}
        classname={classname}
        isSplit={true}/>
      }
      tableAction={<PlaylistTracksSplitTableAction
        active={this.state.actionActive}
        classname={classname}
        count={this.props.tracks.length}
        countSplit={this.props.tracksSplit.length}
        placeholder="Filter tracks..."
        numSelected={this.selectedTracks.length}
        deselectTracks={()=>this.deselectTracks()}
        action={(e)=>this.filterTracks(e)}
        changeInfo={()=>this.changeInfo()}
        addToQueue={()=>this.addToQueue()}
        removeTracks={()=>this.removeTracks()}
        getBreakdown={(data)=>this.getBreakdown(data)}
        openDesignation={(designation,id)=>this.props.openDesignation(designation,id)}/>
      }
      />
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-tracks" height="100%">
          {this.getTable()}
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    tracksSplit:store.playlist.tracksSplit,
    tracks:store.playlist.tracks,
    metadata:store.playlist.metadata,
    filter:store.playlist.filter,
    loading:store.playlist.loading
  }
}

export default connect(mapStateToProps)(PlaylistTracksSplit)
