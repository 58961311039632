import { combineReducers } from 'redux'

import { routerReducer } from 'react-router-redux'

import auth from './authReducer'
import dashboard from './dashboardReducer'
import playlists from './playlistsReducer'
import player from './playerReducer'
import playlist from './playlistReducer'
import venues from './venuesReducer'
import venue from './venueReducer'
import venueWS from './venueWSReducer'
import businesses from './businessesReducer'
import business from './businessReducer'
import overlay from './overlayReducer'
import library from './libraryReducer'
import users from './usersReducer'
import user from './userReducer'
import messages from './messagesReducer'
import messageOverlay from './messageOverlayReducer'
import queue from './queueReducer'
import typeahead from './typeaheadReducer'
import fitness from './fitnessReducer'
import confirm from './confirmReducer'
import upload from './uploadReducer'

const appReducer = combineReducers({
    auth,
    dashboard,
    playlists,
    playlist,
    player,
    venues,
    venue,
    overlay,
    businesses,
    business,
    library,
    users,
    user,
    messages,
    venueWS,
    messageOverlay,
    queue,
    typeahead,
    fitness,
    confirm,
    upload,
    router:routerReducer
})

const rootReducer = (state, action) => {
    //https://medium.com/@asher.cassetto.cohen/resetting-redux-state-with-a-root-reducer-bonus-how-to-reset-state-selectively-e2a008d0de61
    if (action.type === 'LOGOUT') {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer