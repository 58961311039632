import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistsOverlay,
  clearPlaylists,
  addTrackToPlaylistLibrary,
  removeTrackFromPlaylistLibrary
} from 'store/actions/playlistAction'

import { getRowLimit } from 'helpers/TableHelper'

import Container from 'ui/Container'
import Tabs from 'ui/Tabs'
import TableWrapper from 'ui/TableWrapper'

import OverlayPlaylistsTracksAddSearch from './playlists-tracks-add/OverlayPlaylistsTracksAddSearch'
import OverlayPlaylistsTracksAddTableRowActions from './playlists-tracks-add/OverlayPlaylistsTracksAddTableRowActions'
import OverlayPlaylistsTracksAddTableRow from './playlists-tracks-add/OverlayPlaylistsTracksAddTableRow'

const classname = 'overlayPlaylistsTracksAdd'

class OverlayPlaylistsTracksAdd extends Component{

  constructor(props){
    super(props)
    this.state = {
      tab:'pinned'
    }
  }

  componentDidMount(){
    this.props.dispatch(clearPlaylists())
    //send track id so we can set assigned playlists
    this.props.dispatch(getPlaylistsOverlay(this.state.tab,getRowLimit(classname),0,this.props.data.track.id))
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.tab !== this.state.tab && this.state.tab !== 'search') {
      //send track id so we can set assigned playlists
      this.props.dispatch(getPlaylistsOverlay(this.state.tab,getRowLimit(classname),0,this.props.data.track.id))
    }
  }

  selectTab(tab){
    this.props.dispatch(clearPlaylists())
    this.setState({tab:tab})
  }

  clearPlaylists(){
    this.props.dispatch(clearPlaylists())
  }

  getPlaylists(limit,offset){
    this.props.dispatch(getPlaylistsOverlay(this.state.tab,limit,offset,this.props.data.track.id))
  }

  assignPlaylist(playlist){
    if(playlist.isTrackAssigned){
      //unassign playlist
      this.props.dispatch(removeTrackFromPlaylistLibrary(
        playlist,
        this.props.data.track.id
      ))
    } else {
      //assign playlist
      this.props.dispatch(addTrackToPlaylistLibrary(
        this.props.data.track.id,
        playlist.id
      ))
    }
  }

  getComponent(){
    const {
      addTrackToPlaylistLoading,
      count,
      data,
      loading,
      playlists
    } = this.props

    switch(this.state.tab){
      case 'assigned':
      case 'recent':
        return (
          <TableWrapper
            classname={classname}
            data={playlists}
            count={count}
            clearData={()=>this.clearPlaylists()}
            getData={(limit,offset)=>this.getPlaylists(limit,offset)}
            loading={loading}
            rowComp={
              <OverlayPlaylistsTracksAddTableRow
                assignPlaylist={(playlist)=>this.assignPlaylist(playlist)}
                playlistID={data.playlistID}
              />
            }
            rowActions={
              <OverlayPlaylistsTracksAddTableRowActions
                addTrackToPlaylistLoading={addTrackToPlaylistLoading}
                classname={classname}
              />
            }
          />
        )
      case 'pinned':
        return (
          <TableWrapper
            controls
            classname={classname}
            data={playlists}
            count={count}
            clearData={()=>this.clearPlaylists()}
            getData={(limit,offset)=>this.getPlaylists(limit,offset)}
            loading={loading}
            rowComp={
              <OverlayPlaylistsTracksAddTableRow
                assignPlaylist={(playlist)=>this.assignPlaylist(playlist)}
                playlistID={data.playlistID}
              />
            }
            rowActions={
              <OverlayPlaylistsTracksAddTableRowActions
                addTrackToPlaylistLoading={addTrackToPlaylistLoading}
                classname={classname}
              />
            }
          />
        )
      case 'owned':
        return (
          <TableWrapper
            controls
            classname={classname}
            data={playlists}
            count={count}
            clearData={()=>this.clearPlaylists()}
            getData={(limit,offset)=>this.getPlaylists(limit,offset)}
            loading={loading}
            rowComp={
              <OverlayPlaylistsTracksAddTableRow
                assignPlaylist={(playlist)=>this.assignPlaylist(playlist)}
                playlistID={data.playlistID}
              />
            }
            rowActions={
              <OverlayPlaylistsTracksAddTableRowActions
                addTrackToPlaylistLoading={addTrackToPlaylistLoading}
                classname={classname}
              />
            }
          />
        )
      case 'search':
        return (
          <OverlayPlaylistsTracksAddSearch
            assignPlaylist={(playlist)=>this.assignPlaylist(playlist)}
            addTrackToPlaylistLoading={addTrackToPlaylistLoading}
            data={data}
          />
        )

      default: //no default case
    }
  }

  render(){
    return (
      <Container classname={classname} maxWidth height="100%" column>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            classname={classname}/>
        </Container>
        <Container height="100%" classname={classname} column>
          <Container height="100%">
            {this.getComponent()}
          </Container>
        </Container>
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    overlay:store.overlay,
    playlists:store.playlists.data,
    count:store.playlists.count,
    loading:store.playlists.loading,
    addTrackToPlaylistLoading: store.playlist.addTrackToPlaylistLoading
  }
}
export default connect(mapStateToProps)(OverlayPlaylistsTracksAdd)
