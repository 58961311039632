import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getBusinessDetails,
  clearBusiness,
  updateBusinessDetails
} from 'store/actions/businessAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'businessDetails'

class BusinessDetails extends Component {
  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    this.props.dispatch(clearBusiness())
    this.props.dispatch(getBusinessDetails(this.props.id))
  }

  componentDidUpdate(prevProps){
    if(this.props.business !== prevProps.business){
      this.props.setHeader(`${this.props.business.name}`)
    }
  }

  onSubmit(form){
    this.props.dispatch(updateBusinessDetails(
      this.props.id,
      form
    ))
  }

  render(){
    const {
      business
    } = this.props
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth>
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            messages={this.state.message}
            data={business}
            statuses={business.statuses}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    business:store.business.details,
    loading:store.business.loading
  }
}

export default connect(mapStateToProps)(BusinessDetails)
