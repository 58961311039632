import React from 'react'

import CSVDownload from 'components/CSVDownload'

import Filter from 'ui/Filter'
import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import Badge from 'ui/Badge'
import Select from 'ui/Select'

const PlaylistTracksTableAction = (props) => {
  const {
    classname,
    action,
    placeholder,
    removeTracks,
    addToQueue,
    changeInfo,
    metadata,
    active,
    getBreakdown,
    count,
    openDesignation,
    numSelected,
    addTracksToPlaylist,
    deselectTracks,
    CSVData,
    CSVFileName,
    changeDesignations,
    removeDuplicates
  } = props

  return (
    <div className={`table-actions table-actions-${classname}`}>
      <div className="table-actions-left">
        <Filter placeholder={placeholder} action={(e)=>action(e)}/>
      </div>
      <div className={`table-actions-right ${active ? 'active' : ''}`}>
        {
          numSelected > 0 &&
          <div className="selected-indicator">{numSelected} selected</div>
        }
        <Icon name="close-circle-outline" action={()=>deselectTracks()}>
          <Tooltip text="Deselect tracks" pos="left"/>
        </Icon>
        {
          metadata && metadata.A ? 
          <Select
          options={[
            {value:'A',display:'A'},
            {value:'B',display:'B'},
            {value:'C',display:'C'},
            {value:'D',display:'D'}
          ]}
          defaultOption="split"
          action={(e)=>changeDesignations(e)}/> :
          null
        }
        <Icon name="ios-create" action={()=>changeInfo()}>
          <Tooltip text="Edit tracks" pos="left"/>
        </Icon>
        <Icon name="ios-list-box" action={()=>addToQueue()}/>
        <Icon name="add-circle" action={()=>addTracksToPlaylist()}>
          <Tooltip text="Add tracks to playlist" pos="left"/>
        </Icon>
        <Icon name="ios-arrow-dropright-circle" action={()=>removeTracks()}>
          <Tooltip text="Remove selected tracks" pos="left"/>
        </Icon>
      </div>
      <div className={`metadata`}>
          {metadata && metadata.A ? <Badge action={()=>openDesignation('A',metadata.A.playlist_id)} data={`A (${metadata.A.total})`}/> : ''}
          {metadata && metadata.B ? <Badge action={()=>openDesignation('B',metadata.B.playlist_id)} data={`B (${metadata.B.total})`}/> : ''}
          {metadata && metadata.C ? <Badge action={()=>openDesignation('C',metadata.C.playlist_id)} data={`C (${metadata.C.total})`}/> : ''}
          {metadata && metadata.D ? <Badge action={()=>openDesignation('D',metadata.D.playlist_id)} data={`D (${metadata.D.total})`}/> : ''}
          {count ? <Badge data={`Total ${count}`}/> : ''}
          {metadata && metadata.lowestEnergy ? <Badge data={`Lowest energy ${metadata.lowestEnergy}`}/> : ''}
          {metadata && metadata.highestEnergy ? <Badge data={`Highest energy ${metadata.highestEnergy}`}/> : ''}
          {metadata && metadata.energy ? <Badge data={`Avg. energy ${metadata.energy}`}/> : ''}
          {metadata && metadata.total_length ? <Badge data={`Length ${metadata.total_length}`}/> : ''}
          {metadata && metadata.new ? <Badge data={`${metadata.new} New`}/> : ''}
          {metadata ? <Badge action={()=>getBreakdown(metadata)} data="Get breakdown"/> : ''}
          {metadata && (
            <Badge
              action={()=>{return false}}
              data={
                <CSVDownload
                  ctaText='Download spreadsheet'
                  data={CSVData}
                  fileName={CSVFileName}
                />
              }
            />
          )}
          { metadata && 
            metadata.duplicates ? 
            <Badge action={()=>removeDuplicates()} type="error" data={`Remove ${metadata.duplicates} duplicates`}/> : 
            null
          }
          {metadata && metadata.parentID > 0 ? <Badge action={()=>window.location.href = `/playlists/${metadata.parentID}?tab=tracks`} data="View parent"/> : ''}
        </div>
    </div>
  )
}

export default PlaylistTracksTableAction
