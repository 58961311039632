import React, {Component} from 'react'

import { debounce } from 'helpers/Debounce'
import {indexOfArrayObject} from 'helpers/ArrayHelper'
import Icon from 'ui/Icon'

class FormTagger extends Component{

  constructor(props){
    super(props)
    this.sendResponse = debounce(this.sendResponse,300)
    this.taggerInput = React.createRef()
    this.taggerRefs = []
    this.state = {
      filter:'',
      term:'',
      results:[],
      cursor:-1,
      cursorSelected:null
    }
  }

  componentDidMount(){
    this.removeTagsFromData()
  }

  componentDidUpdate(prevProps){
    if(prevProps !== this.props){
      this.removeTagsFromData()
    }
  }

  handleKeyDown(e){
    //e.preventDefault()
    const {
      cursor,
      data
    } = this.state
    // arrow up/down button should select next/previous list element
    // up arrow
    if (e.key === 'ArrowUp' && cursor > 0) {
      this.setState( prevState => ({
        cursor: prevState.cursor - 1,
        cursorSelected:data[prevState.cursor - 1]
      }),()=>{
        this.getResults()
      })
    //down arrow
    } else if (e.key === 'ArrowDown' && cursor < data.length - 1) {
      this.setState( prevState => ({
        cursor: prevState.cursor + 1,
        cursorSelected:data[prevState.cursor + 1]
      }),()=>{
        this.getResults()
      })
    //on enter
    } else if(e.key === 'Enter' && this.taggerInput.current.value.length > 0){
      e.preventDefault()
      this.addTag(this.state.cursorSelected)
      this.setState({
        cursor:-1,
        cursorSelected:null
      })
    }
  }

  removeTagsFromData(){
    if(this.props.allData && this.props.allData.length > 0){
      const filteredData = this.props.allData
        .filter((elem)=> {
          if(indexOfArrayObject(this.props.data,elem,'id') === -1){
            return elem
          }
          return false
        })
      this.setState({filteredAllData:filteredData})
    }
  }

  getResults(){
    const {
      filteredAllData,
      filter,
      cursor
    } = this.state

    if(filteredAllData && filteredAllData.length > 0 && filter.length > 0){
      const data = this.state.filteredAllData
        .filter((datum)=>{
          if(datum[this.props.display] && datum[this.props.display].toLowerCase().includes(filter)){
            this.taggerRefs[datum.id] = React.createRef()
            return datum
          }
          return false
        })

        const results = data.map((datum,index)=>{
          return <div
            ref={this.taggerRefs[datum.id]}
            onClick={() => this.addTag(datum)}
            key={index}
            className={`form-tagger-input-result ${cursor === index && 'form-tagger-input-result-active'}`}>
              {datum[this.props.display]}
            </div>
        })
        this.setState({
          results:results,
          data:data
        })
    }
  }

  inputOnChange(e){
    this.setState({
      filter: e.target.value.toLowerCase()
    },()=>{
      this.getResults()
    })
  }

  clearTaggerInput(){
    this.setState({results:[]},()=>{
      this.taggerInput.current.value = ''
    })
  }

  addTag(tag){
    this.props.addTag(tag)
    this.clearTaggerInput()
  }

  deleteTag(tag){
    this.props.removeTag(tag)
  }

  getTags(){
    if(this.props.data){
      return this.props.data
        .map((datum,index)=>{
          return (
            <div key={index} className={`form-tag form-tag-${this.props.classname}`}>
              {datum[this.props.display]}<Icon name="close" action={()=>this.deleteTag(datum)}/>
            </div>
          )
        })
    } else {
      return []
    }
  }

  render(){
    const {classname, placeholder} = this.props
    return (
      <div className={`form-tagger-wrapper form-tagger-wrapper-${classname}`}>
        <input
          ref={this.taggerInput}
          className={`form-tagger-input form-tagger-input-${classname}`}
          onChange={(e)=>this.inputOnChange(e)}
          onKeyDown={(e)=>this.handleKeyDown(e)}
          placeholder={placeholder}/>
          <Icon
            name="ios-close"
            action={()=>this.clearTaggerInput()}
            classname={`form-tagger-input-clear ${this.taggerInput.current && this.taggerInput.current.value.length > 0 ? 'active' : ''}`}/>
          <div className={`
            form-tagger-input-results 
            form-tagger-input-results-${this.props.classname} 
            ${this.state.results.length > 0 ? 'form-tagger-input-results-active' : ''}`}>
            {this.state.results}
          </div>
          <div className={`form-tagger-tags form-tagger-tags-${classname}`}>
            {this.getTags()}
          </div>
      </div>
    )
  }
}

export default FormTagger
