import React, {Component} from 'react'
import { connect } from 'react-redux'

import { getPlaylistDetails, updatePlaylistDetails } from 'store/actions/playlistAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'playlistDetails'

class PlaylistDetails extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null
    }
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistDetails(this.props.id))
  }

  onSubmit(form){
    this.props.dispatch(updatePlaylistDetails(this.props.id, form))
  }

  getPlaylistTypes(){
    if(this.props.details.allTypes){
      return this.props.details.allTypes.map((type)=>{
        const res = {display:type.type,value:type.id}
        return res
      })
    } else {
      return []
    }
  }

  getPlaylistRotation(){
    const {
      details
    } = this.props

    if (typeof details.rotation !== 'undefined') {
      return [
        {
          label: '\'A\' always in (A,B,C)',
          value: 0,
          defaultChecked: details.rotation === 0
        },
        {
          label: 'Rotate all (A,B,C)',
          value: 1,
          defaultChecked: details.rotation === 1
        },
        {
          label: '\'A\' always in (A,B,C,D)',
          value: 2,
          defaultChecked: details.rotation === 2
        },
        {
          label: 'Rotate all (A,B,C,D)',
          value: 3,
          defaultChecked: details.rotation === 3
        }
      ]
    } else {
      return []
    }
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-details" height="100%">
          <Form
            classname={classname}
            submit={(e)=>this.onSubmit(e)}
            messages={this.state.message}
            data={this.props.details}
            playlistTypes={this.getPlaylistTypes()}
            rotation={this.getPlaylistRotation()}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    details:store.playlist.details,
    loading:store.playlist.playlistDetailsLoading
  }
}

export default connect(mapStateToProps)(PlaylistDetails)
