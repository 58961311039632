import React from 'react'

import FormLabel from 'ui/FormLabel'

export const FormSelect = (props) => {
  const { classname, name, options, action, selected, label } = props
  return (
    <div className={`form-select-wrapper`}>
      <FormLabel label={label}/>
      <select
        name={name}
        className={`form-select select-${classname}`}
        onChange={(e)=>action(e)}
        value={selected}
      >
        {options.map((option, index) => {
          return (
            <option
              key={index}
              value={option.value}
            >
              {option.display}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default FormSelect
