import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'qs'

import {
  getPlaylists,
  clearPlaylists,
  pinPlaylist,
  unpinPlaylist,
  playlistTypeaheadAll,
  clearPlaylistTypeahead,
} from 'store/actions/playlistAction'

import { openMessageOverlay } from 'store/actions/messageOverlayAction'
import { getRowLimit } from 'helpers/TableHelper'
import { toggleOverlay } from 'store/actions/overlayAction'

import PlaylistsSearch from './playlists/PlaylistsSearch'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'
import PlaylistsTableActions from './playlists/PlaylistsTableActions'
import PlaylistsPinnedTableActions from './playlists/PlaylistsPinnedTableActions'
import PlaylistsImporter from './playlists/PlaylistsImporter'
import PlaylistsCreate from './playlists/PlaylistsCreate'

const classname = 'playlists'

class PlaylistsContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:null,
      header: ''
    }
  }

  componentDidMount(){
    this.getTabQuery()
  }

  componentDidUpdate(prevProps, prevState){
    if(
        prevState.tab !== this.state.tab &&
        this.state.tab !== 'search' &&
        this.state.tab !== 'create' &&
        this.state.tab !== 'importer' &&
        this.state.tab
      ) {
      this.props.dispatch(clearPlaylists())
      this.props.dispatch(getPlaylists(this.state.tab,getRowLimit(classname),0))
    }

    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.setState({tab: query.tab})
    } else if(
      query.tab !== 'search' &&
      query.tab !== 'create' &&
      query.tab !== 'importer' &&
      typeof query.tab !== 'undefined'
    ){
      this.props.dispatch(clearPlaylists())
      this.props.dispatch(getPlaylists(this.state.tab,getRowLimit(classname),0))
    } else if(typeof query.tab === 'undefined'){
      this.setState({tab: 'pinned'})
    }
  }

  selectTab(tab){
    this.setState({tab:tab},()=>{
      this.props.history.push({
        search: `?tab=${tab}`
      })
    })
  }

  setHeader(header) {
    this.setState({
      header: header
    })
  }

  clearPlaylists(){
    this.props.dispatch(clearPlaylists())
  }

  getPlaylists(limit,offset,sorter){
    this.props.dispatch(getPlaylists(this.state.tab,limit,offset,sorter))
  }

  moreActions(data){
    console.log(data)
    this.props.dispatch(toggleOverlay(true,data,'playlistsMore'))
  }

  searchPlaylists(term){
    this.props.dispatch(playlistTypeaheadAll(term))
  }

  clearTypeahead(){
    this.props.dispatch(clearPlaylistTypeahead())
  }

  pinPlaylist(data){
    this.props.dispatch(pinPlaylist(data.id))
    this.props.dispatch(openMessageOverlay('info',`Playlist pinned.`))
  }

  unpinPlaylist(data){
    const playlist = this.props.playlists.find(playlist => playlist.id === data.id)
    this.props.dispatch(unpinPlaylist(playlist))
    this.props.dispatch(openMessageOverlay('info',`Playlist unpinned.`))
  }

  sortPlaylists(header){
    this.clearPlaylists()
    this.props.dispatch(getPlaylists(this.state.tab,getRowLimit(classname),0,header))
  }

  getComponent(){
    const {
      history,
    } = this.props

    switch(this.state.tab){
      case 'search':
        return <PlaylistsSearch more={(data)=>this.moreActions(data)}/>
      case 'owned':
      case 'admin':
      case 'special':
      case 'child':
        return <TableWrapper
          controls
          classname={classname}
          data={this.props.playlists}
          count={this.props.count}
          clearData={()=>this.clearPlaylists()}
          getData={(limit,offset,sorter)=>this.getPlaylists(limit,offset,sorter)}
          sortTable={(head)=>this.sortPlaylists(head)}
          loading={this.props.loading}
          rowActions={<PlaylistsTableActions
            more={(data)=>this.moreActions(data)}
            pinPlaylist={(data)=>this.pinPlaylist(data)}
            classname={classname}/>
          }
          />
      case 'recent':
        return <TableWrapper
          classname={classname}
          data={this.props.playlists}
          count={this.props.count}
          clearData={()=>this.clearPlaylists()}
          getData={(limit,offset,sorter)=>this.getPlaylists(limit,offset,sorter)}
          sortTable={(head)=>this.sortPlaylists(head)}
          loading={this.props.loading}
          rowActions={<PlaylistsTableActions
            more={(data)=>this.moreActions(data)}
            pinPlaylist={(data)=>this.pinPlaylist(data)}
            classname={classname}/>
          }/>
      case 'pinned':
        return <TableWrapper
          controls
          classname={classname}
          data={this.props.playlists}
          count={this.props.count}
          clearData={()=>this.clearPlaylists()}
          getData={(limit,offset,sorter)=>this.getPlaylists(limit,offset,sorter)}
          sortTable={(head)=>this.sortPlaylists(head)}
          loading={this.props.loading}
          rowActions={<PlaylistsPinnedTableActions
            more={(data)=>this.moreActions(data)}
            unpinPlaylist={(data)=>this.unpinPlaylist(data)}
            classname={classname}/>
          }/>
      case 'create':
        return (
          <PlaylistsCreate
            history={history}
          />
        )
      case 'importer':
        return (
          <PlaylistsImporter
            setHeader={(header)=>this.setHeader(header)}
            history={history}
          />
        )
      default: // no default case
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper`} height="100%" column>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            classname={classname}
            header={this.state.header}
          />
        </Container>
        <Container height="100%" classname={`${classname} container-tabview`} column>
          <Container height="100%">
            {this.getComponent()}
          </Container>
        </Container>
      </Container>
    )
  }
}
function mapStateToProps(store,myProps){
  return {
    playlists:store.playlists.data,
    typeahead:store.playlists.typeahead,
    count:store.playlists.count,
    loading:store.playlists.loading,
    tab:myProps.location.search
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistsContainer))
