import axios from 'axios'
const CancelToken = axios.CancelToken
let cancel

export function getTracks(url,limit,offset,sort = null,filter = null){

  let headers = {
    'Limit':limit,
    'Offset':offset
  }

  if(sort && sort.direction){
    headers['Sort'] = true
    headers['Field'] = sort.field
    headers['Direction'] = sort.direction
  }

  return (dispatch) => {
    return dispatch({
      type:'FETCH_TRACKS',
      payload:axios.post(url,filter,{headers:headers})
    })
  }
}

export function getGenres(){
  return (dispatch) => {
    return dispatch({
      type:'GET_GENRES',
      payload:axios.get('library/genres')
    })
  }
}

export function clearTracks(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_TRACKS',
      payload:null
    })
  }
}

export function searchLibrary(term, limit, offset, sort = null,advancedSearch){

  let headers = {
    'Limit':limit,
    'Offset':offset
  }

  if(sort && sort.direction){
    headers['Sort'] = true
    headers['Field'] = sort.field
    headers['Direction'] = sort.direction
  }

  const url = advancedSearch ? 'library/search' : 'library/search/old'

  return (dispatch) => {
    cancel && cancel('TYPEAHEAD_TRACKS request cancelled by SEARCH_TRACKS')
    return dispatch({
      type:'SEARCH_TRACKS',
      payload:axios.post(url,{term:term},{headers:headers})
    })
  }
}

export function searchLibraryTypeahead(term){
  return (dispatch) => {
    cancel && cancel('TYPEAHEAD_TRACKS request cancelled')

    const request = axios.post('library/typeahead',
      {term:term},
      {cancelToken: new CancelToken(function executor(c) {
        cancel = c
      })
    }).catch((cancel) => {
      console.log(cancel)
    })

    return dispatch({
      type:'TYPEAHEAD_TRACKS',
      payload:request
    })
  }
}

export function clearTypeaheadTracks(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_TYPEAHEAD_TRACKS',
      payload:null
    })
  }
}

export function favouriteTrack(track){
  return (dispatch) => {
    return dispatch({
      type:'FAVOURITE_TRACK',
      payload:axios.put('')
    })
  }
}

export function addTrackToQueue(track){
  return (dispatch) => {
    return dispatch({
      type:'ADD_TRACK_TO_QUEUE',
      payload:track
    })
  }
}

export function getTrack(track_id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_TRACK_DETAILS',
      payload:axios.get(`/library/track/details/${track_id}`)
    })
  }
}

export function updateTrack(track_id,data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_TRACK_DETAILS',
      payload:axios.post(`/library/track/details/${track_id}`,data),
      meta:{
        track_id:track_id,
        data:data
      }
    })
  }
}

export function updateTracks(tracks,data){
  const postData = {
    tracks:tracks,
    data:data
  }
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_TRACKS_DETAILS',
      payload:axios.post(`/library/tracks/details`,postData),
      meta:{
        tracks:tracks,
        data:data
      }
    })
  }
}

export function updateTrackEnergy(track_id,energy){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_TRACK_ENERGY',
      payload:axios.post(`/library/track/details/${track_id}`,{energy:energy}),
      meta:{
        track_id:track_id,
        energy:energy
      }
    })
  }
}

export function updateTrackGenres(track_id,genres){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_TRACK_GENRES',
      payload:axios.post(`/library/track/details/${track_id}`,{genres:genres}),
      meta:{
        track_id:track_id,
        genres:genres
      }
    })
  }
}

export function deleteTrack(track_id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_TRACK',
      payload:axios.delete(`/library/track/${track_id}`)
    })
  }
}
