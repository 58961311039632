import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import queryString from 'qs'
import { parseQueryString } from 'helpers/StringHelper'

import {
  getPlaylistDetails,
  pinPlaylist
} from 'store/actions/playlistAction'

import { openMessageOverlay } from 'store/actions/messageOverlayAction'

import PlaylistDetails from './playlist/PlaylistDetails'
import PlaylistTracks from './playlist/PlaylistTracks'
import PlaylistLibrary from './playlist/PlaylistLibrary'
import PlaylistTracksSplit from './playlist/PlaylistTracksSplit'
import PlaylistOrder from './playlist/PlaylistOrder'
import PlaylistVenues from './playlist/PlaylistVenues'
import PlaylistChildren from './playlist/PlaylistChildren'
import PlaylistCurators from './playlist/PlaylistCurators'
import PlaylistImage from './playlist/PlaylistImage'
import PlaylistTracksToImport from './playlist/PlaylistTracksToImport'
import PlaylistTracksToApprove from './playlist/PlaylistTracksToApprove'

import PlaylistTabActions from './playlist/PlaylistTabActions'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import Loader from 'ui/Loader'
import { toggleOverlay } from "store/actions/overlayAction"
import PlaylistSettings from "./playlist/PlaylistSettings"

const classname = 'playlist'

class PlaylistContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'',
      header:null,
      designationTabs:[]
    }
  }

  componentDidMount(){
    this.getTabQuery()
    //this.props.dispatch(getPlaylistQuick(this.props.match.params.id))
    this.props.dispatch(getPlaylistDetails(this.props.match.params.id))

    // if the playlist comes from a spotify import,
    // add the "Tracks to import" tab
    this.setTracksToImportTab()
  }

  componentDidUpdate(prevProps,prevState){
    const {
      playlist,
      tracks,
      playlistUpdated,
      tracksToApprove
    } = this.props

    //check playlist change
    if(prevProps.match.params.id !== this.props.match.params.id){
      this.props.dispatch(getPlaylistDetails(this.props.match.params.id))
    }
    //if split/merge from playlist page
    if(playlistUpdated && prevProps.playlistUpdated !== playlistUpdated){
      this.props.dispatch(getPlaylistDetails(this.props.match.params.id))
    }

    if(tracks !== prevProps.tracks){
      this.setHeader()
    } else if(playlist !== prevProps.playlist){
      this.setHeader()
    }

    // if the playlist comes from a spotify import,
    // add the "Tracks to import" tab
    if ((prevProps.playlist.hasTracksToImport !== playlist.hasTracksToImport) && playlist.hasTracksToImport) {
      setTimeout(()=>{
        this.setTracksToImportTab()
      },1)
    }

    // if the playlist has changes to approve,
    // add the corresponding tab
    if ((prevProps.playlist.hasChangesToApprove !== playlist.hasChangesToApprove) && playlist.hasChangesToApprove) {
      setTimeout(()=>{
        this.setChangesToApproveTab()
      },1)
    }

    //  handle forward and back browser button usage
    //if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
    if (this.props.location !== prevProps.location) {
      this.getTabQuery()
    }
    //update tracks to import badge
    if(prevProps.playlist.hasTracksToImport !== playlist.hasTracksToImport){
      this.updateImportBadge()
    }
    //update tracks to approve badge
    if(prevProps.tracksToApprove !== tracksToApprove){
      this.updateApproveBadge()
    }
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))
    if(typeof query.tab !== 'undefined') {
      // catch library search queries and display the library tab
      if(query.tab.match(/search|all/)) {
        this.setState({tab: 'library'})
      } else {
        this.setState({tab: query.tab})
      }
    } else {
      // defines the default tab to loaded when no tab query is passed in the URL
      this.setState({tab: 'tracks'})
    }
  }

  selectTab(tab){
    this.props.history.push({
      search: `?tab=${tab}`
    })
    this.setState({tab:tab})
  }

  setHeader() {
    const {
      playlist,
      tracks
    } = this.props

    const header = `${playlist.displayName || playlist.name} - ${tracks.length > 0 ? tracks.length : playlist.trackCount} tracks`
    this.setState({header:header})
  }

  updateImportBadge(){
    const {
      designationTabs
    } = this.state

    const {
      playlist
    } = this.props

    this.setState({
      designationTabs:designationTabs.map(tab => {
        if(tab.link === 'tracks_to_import'){
          tab.badge = playlist.hasTracksToImport
        }
        return tab
      })
    })
  }

  updateApproveBadge(){
    const {
      designationTabs
    } = this.state

    const {
      tracksToApprove
    } = this.props

    this.setState({
      designationTabs:designationTabs.map(tab => {
        if(tab.link === 'changes_to_approve'){
          tab.badge = tracksToApprove.length
        }
        return tab
      })
    })
  }
  
  updateImportBadge(){
    const {
      designationTabs
    } = this.state

    const {
      playlist
    } = this.props

    this.setState({
      designationTabs:designationTabs.map(tab => {
        if(tab.link === 'tracks_to_import'){
          tab.badge = playlist.hasTracksToImport
        }
        return tab
      })
    })
  }

  setTracksToImportTab() {
    const {
      playlist
    } = this.props

    const {
      designationTabs
    } = this.state

    //let designationTabsArray = [...designationTabs]

    if (playlist.hasTracksToImport && !designationTabs.some(tab => tab.link === 'tracks_to_import')) {
      const tab = {
        name: 'Tracks to import',
        link: 'tracks_to_import',
        badge:playlist.hasTracksToImport
      }
      this.setState({
        designationTabs: this.state.designationTabs.concat(tab)
      })
    }
    if(!playlist.hasTracksToImport && designationTabs.some(tab => tab.link === 'tracks_to_import')){
      const designationTabsArray = designationTabs.filter(tab => tab.link !== 'tracks_to_import')
      this.setState({
        designationTabs: designationTabsArray
      })
    }
  }

  setChangesToApproveTab() {
    const {
      playlist
    } = this.props

    const {
      designationTabs
    } = this.state

    //let designationTabsArray = [...designationTabs]

    if (playlist.hasChangesToApprove && !designationTabs.some(tab => tab.link === 'changes_to_approve')) {
      this.setState({
        designationTabs: designationTabs.concat({
          name: 'Changes to approve',
          link: 'changes_to_approve',
          badge:playlist.hasChangesToApprove
        })
      })
    }
    if(!playlist.hasChangesToApprove && designationTabs.some(tab => tab.link === 'changes_to_approve')){
      this.setState({
        designationTabs:designationTabs.filter(tab => tab.link !== 'changes_to_approve')
      })
    }
  }

  openDesignation(designation,id){
    const link = `?tab=tracks&designation=${id}&type=${designation}`
    this.setState({
      designationTabs:this.state.designationTabs
        .concat(
          {
            name:`Split ${designation}`,
            id:id,
            link:link,
            dismiss:true
          }
        ),
      tab:link
    },()=>{
      this.props.history.push({
        search: link
      })
    })
  }

  getComponent(){
    const id = this.props.match.params.id
    const {designation, type} = parseQueryString(this.props.location.search)
    const {
      history,
      location,
      playlist,
      playingTrack,
      playlistMetadata
    } = this.props

    if(designation){
      return <PlaylistTracksSplit id={designation} designationType={type} parent={id}/>
    } else {
      switch(this.state.tab){
        case 'details':
          return <PlaylistDetails id={id}/>
        case 'tracks':
          return (
            <PlaylistTracks
              id={id}
              openDesignation={(designation,id) => this.openDesignation(designation,id)}
              playlistName={playlist.name}
            />
          )
        case 'order':
          return <PlaylistOrder id={id}/>
        case 'venues':
          return <PlaylistVenues id={id}/>
        case 'children':
          return <PlaylistChildren id={id} history={this.props.history}/>
        case 'curators':
          return <PlaylistCurators id={id}/>
        case 'image':
          return <PlaylistImage id={id}/>
        case 'settings':
          return <PlaylistSettings id={id}/>
        case 'library':
          return (
            <PlaylistLibrary
              history={history}
              id={id}
              location={location}
              playlist={playlist}
              playingTrack={playingTrack}
              playlistMetadata={playlistMetadata}
            />
          )
        case 'tracks_to_import':
          return (
            <PlaylistTracksToImport
              id={id}
              playlistName={playlist.name}
            />
          )
        case 'changes_to_approve':
          return (
            <PlaylistTracksToApprove
              id={id}
              playlistName={playlist.name}
            />
          )
        default: // no default case
      }
    }
  }

  closeTab(tab){
    const designationTabs = this.state.designationTabs
      .filter(tb => tb !== tab)
    this.setState({
      designationTabs:designationTabs,
      tab:'tracks'
    },()=>{
      this.selectTab('tracks')
    })
  }

  pinPlaylist(){
    this.props.dispatch(pinPlaylist(this.props.match.params.id))
    this.props.dispatch(openMessageOverlay('info',`Playlist pinned.`))
  }

  playlistMore(){
    const playlist = {
      ...this.props.playlist,
      fromPlaylist:true
    }
    this.props.dispatch(toggleOverlay(true,playlist,'playlistsMore'))
  }

  render(){
    const {
      match,
      playlist,
      playlistDetailsLoading
    } = this.props

    const {
      designationTabs,
      header,
      tab
    } = this.state

    return (
      <Container classname={classname} height="100%" column>
        <Container classname={`tabs`}>
          <Tabs
            match={match}
            select={(tab)=>this.selectTab(tab)}
            active={tab}
            classname={classname}
            add={designationTabs}
            header={playlistDetailsLoading ? <Loader/> : header}
            closeTab={(tab)=>this.closeTab(tab)}
            tabActions={
              <PlaylistTabActions
                isPinned={playlist.isPinned}
                pinPlaylist={()=>this.pinPlaylist()}
                playlistMore={()=>this.playlistMore()}/>
            }/>
        </Container>
        <Container height="100%" classname={`${classname} container-tabview`} column>
          {this.getComponent()}
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    playlist:store.playlist.details,
    playlistMetadata:store.playlist.metadata,
    tracks:store.playlist.tracks,
    tracksToApprove:store.playlist.tracksToApprove,
    loading:store.playlist.loading,
    libraryLoading:store.library.loading,
    playlistDetailsLoading:store.playlist.playlistDetailsLoading,
    playlistUpdated:store.playlist.playlistUpdated
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistContainer))
