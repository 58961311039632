const initalState = {
  isActive:false,
  data:null,
  type:null,
  loading:false
}

export default function reducer(state = initalState,action){
  switch (action.type){
    case 'TOGGLE_OVERLAY':{
      state = {
        ...state,
        isActive:action.payload.isActive,
        data:action.payload.data,
        type:action.payload.type
      }
      break
    }
    case 'COPY_VENUE_PENDING':
    case 'DELETE_BUSINESS_PENDING':
    case 'DELETE_VENUE_PENDING':
    case 'DELETE_USER_PENDING':
    case 'COPY_VENUE_INTERLEAVES_PENDING':
    case 'SAVE_SCHEDULE_PENDING':
    case 'LOAD_SCHEDULE_PENDING':
    case 'COPY_CALENDAR_TO_VENUES_PENDING':
    case 'COPY_CALENDAR_INTERLEAVE_TO_VENUES_PENDING':
    case 'CREATE_SAVED_CALENDAR_PENDING':
    case 'LOAD_SAVED_CALENDAR_PENDING':
    case 'ADD_SAVED_CALENDAR_TO_CALENDAR_PENDING':
    case 'UPDATE_TRACK_DETAILS_PENDING':
    case 'UPDATE_TRACKS_DETAILS_PENDING':
    case 'COPY_VENUE_SCHEDULES_PENDING':{
      state = {
        ...state,
        loading:true
      }
      break
    }
    case 'COPY_VENUE_FULFILLED':
    case 'DELETE_BUSINESS_FULFILLED':
    case 'DELETE_VENUE_FULFILLED':
    case 'DELETE_USER_FULFILLED':
    case 'COPY_VENUE_INTERLEAVES_FULFILLED':
    case 'SAVE_SCHEDULE_FULFILLED':
    case 'LOAD_SCHEDULE_FULFILLED':
    case 'COPY_CALENDAR_TO_VENUES_FULFILLED':
    case 'COPY_CALENDAR_INTERLEAVE_TO_VENUES_FULFILLED':
    case 'CREATE_SAVED_CALENDAR_FULFILLED':
    case 'LOAD_SAVED_CALENDAR_FULFILLED':
    case 'ADD_SAVED_CALENDAR_TO_CALENDAR_FULFILLED':
    case 'UPDATE_TRACK_DETAILS_FULFILLED':
    case 'UPDATE_TRACKS_DETAILS_FULFILLED':
    case 'CREATE_VENUE_ANNOUNCEMENTS_FULFILLED':
    case 'COPY_VENUE_ANNOUNCEMENTS_FULFILLED':
    case 'COPY_VENUE_SCHEDULES_FULFILLED':
    case 'FETCH_BUSINESS_DETAILS_PENDING':
    case 'DELETE_PLAYLIST_FULFILLED':
    case 'FETCH_VENUE_PLAYLISTS_PENDING':
    case 'CREATE_VENUE_DEVICE_FULFILLED':
    case 'UPDATE_VENUE_DEVICE_FULFILLED':
    case 'CREATE_VENUE_REPLACEMENT_SD_FULFILLED':{
      state = {
        ...state,
        loading:false,
        isActive:false
      }
      break
    }
    case 'COPY_VENUE_REJECTED':{
      state = {
        ...state,
        loading:false,
        isActive:true
      }
      break
    }
    default: // no default case
  }
  return state
}
