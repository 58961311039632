import React, { Component } from 'react'

import FormModel from 'models/FormModel'

import Input from 'ui/Input'
import FormInput from 'ui/FormInput'
import FormSelect from 'ui/FormSelect'
import FormLegend from 'ui/FormLegend'
import FormTextarea from 'ui/FormTextarea'
import FormTagger from './FormTagger';
import FormTypeahead from 'ui/FormTypeahead';
import FormChecklist from "./FormChecklist";
import FormCheckbox from './FormCheckbox'
import FormRadio from './FormRadio'
import FormDate from './FormDate'

class Form extends Component{

  constructor(props){
    super(props)
    this.state = {
      errors: [],
      displayTriggerValue: 0
    }

    this.errorArray = []
  }

  componentDidMount(){
    const {
      data
    } = this.props

    if (data) {
      this.setState(data)
    }

    this.setFormState()
  }

  componentDidUpdate(prevProps){
    if(prevProps.data !== this.props.data){
      this.setFormState()
    }
  }

  setFormState(){
    const {
      classname,
      data
    } = this.props

    for(let elem of FormModel[classname].form) {
      if(elem.field) {
        this.setState({
          [elem.field]: data ? data[elem.field] : ''
        })
      }

      // if an element is a display trigger,
      // set the displayTriggerValue state to its value
      if (elem.displayTrigger) {
        this.setState({
          displayTriggerValue:  data ? data[elem.field] : 0
        })
      }
    }

    // If the form has any select elements, set the initial element state to either the first value,
    // or to the defaultValue in the form model, if provided
    const formSelectElements = FormModel[this.props.classname].form.filter(elem => {
      return elem.elem === 'select'
    })

    if (formSelectElements.length > 0) {
      formSelectElements.forEach(elem => {
        // check for the list of possible select options
        // or whether there is a value being preloaded in the data prop
        if (this.props[elem.options].length > 0 && !(data && data[elem.name])) {
          this.setState({
            [elem.name]: elem.defaultValue || this.props[elem.options][0].value
          })
        }
      })
    }
  }

  checkFormError(elem) {
    if (!elem.required) {
      return false
    }

    // if an element is disabled and its enabledBy condition has not been met, exclude it from the error checking
    if (elem.disabled && elem.enabledBy && !this.state[elem.enabledBy]) {
      // remove any errors from the error array if the field is disabled again
      this.errorArray = this.errorArray.filter(error => error !== elem.name)
      return false
    }

    // if the value required to display a hidden field has not been set, exclude it from the error checking
    if (elem.displayTriggerValue && elem.displayTriggerValue !== this.state.displayTriggerValue) {
      return false
    }

    // if the value required to hide a field has been set, exclude it from the error checking
    if (elem.hiddenTriggerValue && elem.hiddenTriggerValue === this.state.displayTriggerValue) {
      return false
    }

    let hasError = false

    // check if the field has a value
    if (!this.state[elem.name]) {
      hasError = true
    }

    //if a typeahead field is required, check it has a name value
    if (
      elem.elem === 'typeahead'
      && !this.state[elem.name]
    ) {
      hasError = true
    }

    // Check if the entry for the phone number is valid
    if (
      elem.name === 'contactNumber'
      && this.state['contactNumber']
      && !this.state['contactNumber'].match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[()\-\s./0-9]*$/g)
    ) {
      hasError = true
    }

    // Check if the entry for the email address is valid
    if (
      elem.name === 'contactEmail'
      && this.state['contactEmail']
      && !this.state['contactEmail'].match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ) {
      hasError = true
    }

    if (elem.isPassword) {
      //check password confirm is the same
      if (this.state[elem.name] !== this.state[elem.confirm]) {
        this.errorArray.push(elem.confirm)
      } else {
        this.errorArray = this.errorArray.filter(error => error !== 'confirmPassword')
      }
    }

    if (elem.isConfirm) {
      //check password is the same
      if (this.state[elem.name] !== this.state[elem.password]) {
        hasError = true
      } else if (this.state[elem.name]) {
        hasError = false
      }
    }

    if (hasError) {
      // if the field has an error, add it to the error array
      this.errorArray.push(elem.name)
    } else {
      // no error, remove it from the array
      this.errorArray = this.errorArray.filter(error => error !== elem.name)
    }

    this.setState({
      errors: [...this.errorArray]
    })
  }

  handleChange(e) {
    const name = e.target.name
    const formElem = FormModel[this.props.classname].form.find(elem=>elem.name === name) || ''
    let value

    if(e.target.type === 'checkbox'){
      value = e.target.checked
    } else {
      value = formElem.valueAsNumber ? parseInt(e.target.value) : e.target.value
    }

    if (formElem.displayTrigger) {
      this.setState({
        displayTriggerValue: parseInt(value)
      })
    }

    this.setState({
      [name]: value
    },()=>this.checkFormError(formElem))
  }

  typeaheadAction(result, elem) {
    const {
      typeaheadAction
    } = this.props

    this.errorArray = this.errorArray.filter(error => error !== elem.name)

    this.setState({
      [elem.name]: result
    }, ()=>{
      if (typeaheadAction) {
        typeaheadAction(result)
      }
    })
  }

  dateChange(date, elem) {
    this.errorArray = this.errorArray.filter(error => error !== elem.name)
    this.setState({
      [elem.name]: date
    })
  }

  clearFormTypeahead (elemName) {
    this.setState({
      [elemName]: ''
    },()=>this.props.clearTypeahead())
  }

  checklistAction(items, elem) {
    this.setState({
      [elem.name]: items
    })
  }

  submitForm(e){
    const {
      submit
    } = this.props

    const formModel = FormModel[this.props.classname].form
    const requiredFormFields = formModel.filter(elem => elem.required)

    e.preventDefault()

    requiredFormFields.forEach((elem) => {
      this.checkFormError(elem)
    })

    if (this.errorArray.length === 0) {
      const formData = {...this.state}

      formModel.forEach((elem) => {
        // if a form element is being hidden via the displayTriggerValue
        // set its value to null on submit
        if (
          (elem.displayTriggerValue && elem.displayTriggerValue !== this.state.displayTriggerValue) ||
          (elem.hiddenTriggerValue && elem.hiddenTriggerValue === this.state.displayTriggerValue)
        ) {
          formData[elem.name] = null
        }
      })

      submit(formData)
    }
  }

  getFormElements(){
    const formElem = FormModel[this.props.classname].form.map((elem,index)=>{
      if (elem.displayTriggerValue && elem.displayTriggerValue !== this.state.displayTriggerValue) {
        return null
      }

      if (elem.hiddenTriggerValue && elem.hiddenTriggerValue === this.state.displayTriggerValue) {
        return null
      }
      //for enable/disable input
      const disabled = elem.enabledBy ? (this.state[elem.enabledBy] ? false : true) : elem.disabled
      switch(elem.elem){
        case 'input':
          if(this.props.altInput){
            return <Input
              type={elem.type}
              key={index}
              name={elem.name}
              value={this.state[elem.name] || ''}
              change={(e)=>this.handleChange(e)}
              classname={this.props.classname}
              label={elem.label}
            />
          } else {
            return <FormInput
              type={elem.type}
              key={index}
              name={elem.name}
              value={this.state[elem.name] || ''}
              change={(e)=>this.handleChange(e)}
              classname={this.props.classname}
              label={elem.label}
              required={elem.required}
              hasError={this.errorArray.find(error=>error === elem.name)}
              errorMessage={elem.errorMessage}
              hasServerError={this.props.serverError && this.props.serverError.data === elem.name}
              serverError={this.props.serverError}
              autocomplete='off'
              disabled={disabled}
              hideIfEmpty={elem.hideIfEmpty}
            />
          }
          case 'legend':
            return <FormLegend key={index} label={elem.label}/>
          case 'select':
            return <FormSelect
              key={index}
              name={elem.name}
              classname={this.props.classname}
              selected={this.state[elem.name]}
              options={this.props[elem.options]}
              action={(e)=>this.handleChange(e)}
              label={elem.label}
            />
          case 'textarea':
            return <FormTextarea
              key={index}
              name={elem.name}
              value={this.state[elem.name] || ''}
              change={(e)=>this.handleChange(e)}
              classname={this.props.classname}
              label={elem.label}
              autocomplete='off'
              hasError={this.errorArray.find(error=>error === elem.name)}
              errorMessage={elem.errorMessage}
              hasServerError={this.props.serverError && this.props.serverError.data === elem.name}
              serverError={this.props.serverError}
              disabled={disabled}
            />
          case 'tagger':
            return <FormTagger
              key={index}
              classname={this.props.classname}
              placeholder={elem.placeholder}
              display={elem.display}
              data={this.props.data[elem.data]}
              allData={this.props[elem.allData]}
              addTag={(tag) => this.props[elem.add](tag)}
              removeTag={(tag) => this.props[elem.remove](tag)}/>
          case 'typeahead':
            return(
              <FormTypeahead
                key={index}
                classname={this.props.classname}
                name={elem.name}
                label={elem.label}
                placeholder={elem.placeholder}
                data={this.props.typeaheadData}
                value={this.props.data ? this.props.data[elem.name] : ''}
                typeaheadSearch={(term)=>this.props.typeaheadSearch(term)}
                typeaheadAction={(result)=>this.typeaheadAction(result, elem)}
                typeaheadLoading={this.props.typeaheadLoading}
                clear={()=>this.clearFormTypeahead(elem.name)}
                disableEnterToSearch={this.props.disableEnterToSearch}
                hasError={this.errorArray.find(error=>error === elem.name)}
                errorMessage={elem.errorMessage}
              />
            )
          case 'checklist':
            return (
              <FormChecklist
                key={index}
                data={this.props.checklistData}
                values={this.state[elem.name] || []}
                checklistAction={(item)=>this.checklistAction(item, elem)}
                checklistErrorMessage={this.props.checklistErrorMessage}
              />
            )
          case 'checkbox':
            return <FormCheckbox
              key={index}
              name={elem.name}
              change={(e)=>this.handleChange(e)}
              classname={this.props.classname}
              label={elem.label}
              checked={this.state[elem.name]}
            />
          case 'radio':
            return <FormRadio
              key={index}
              change={(e)=>this.handleChange(e)}
              classname={this.props.classname}
              label={elem.label}
              name={elem.name}
              options={this.props[elem.name]}
              vertical={elem.vertical}
            />
          case 'date':
            return <FormDate
              key={index}
              setDate={(date)=>this.dateChange(date, elem)}
              classname={this.props.classname}
              label={elem.label}
              name={elem.name}
              date={this.state[elem.name] || ''}
              hasError={this.errorArray.find(error=>error === elem.name)}
              errorMessage={elem.errorMessage}
              hasServerError={this.props.serverError && this.props.serverError.data === elem.name}
              serverError={this.props.serverError}
              disabled={disabled}
            />
        default: // no default case
      }
      return false
    })
    return formElem
  }

  render(){
    const {classname, message, scroll, skipFunction, isOverlay,height } = this.props
    const classes = `form form-${classname} ${scroll && 'scroll'} ${isOverlay && 'isOverlay'}`
    return (
      <form className={classes} onSubmit={(e)=>this.submitForm(e)} style={{
        height:`${height ? height : 'auto'}`
      }}>
        {this.getFormElements()}
        <span className={`form-message form-message-${classname}`}>
          {message}
        </span>
        <button type="submit" className={`form-submit form-submit-${classname}`}>
          {FormModel[classname].submitLabel}
        </button>
        {FormModel[classname].skipLabel && (
          <button
            type="button"
            className={`form-submit form-submit-${classname}`}
            onClick={skipFunction}
          >
            {FormModel[classname].skipLabel}
          </button>
        )}
      </form>
    )
  }
}

export default Form
