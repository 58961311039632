import axios from 'axios'
import Websockets from 'lib/Websockets'
const CancelToken = axios.CancelToken
let cancel
let ws

export function getVenues(limit,offset,initialLoad){

  const headers = {
    'Limit':limit,
    'Offset':offset
  }
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUES',
      payload:axios.get('venues',{headers:headers}),
      meta: initialLoad
    })
  }
}

export function clearVenues(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_VENUES',
      payload:null
    })
  }
}

export function searchVenues(limit,offset,term,initialLoad){
  const headers = {
    'Limit':limit,
    'Offset':offset
  }
  return (dispatch) => {
    cancel && cancel('SEARCH_VENUES_TYPEAHEAD request cancelled by SEARCH_VENUES')
    return dispatch({
      type:'SEARCH_VENUES',
      payload:axios.get(`venues/search/${term}`, {headers:headers}),
      meta: initialLoad
    })
  }
}

export function searchVenuesTypeahead(term){
  return (dispatch) => {
    cancel && cancel('SEARCH_VENUES_TYPEAHEAD request cancelled')

    const request = axios.get(`venues/typeahead/${term}`,
      {cancelToken: new CancelToken(function executor(c) {
        cancel = c
      })
    }).catch((cancel) => {
      console.log(cancel)
    })

    return dispatch({
      type:'SEARCH_VENUES_TYPEAHEAD',
      payload:request
    })
  }
}

export function clearTypeahead(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_TYPEAHEAD'
    })
  }
}

export function clearVenue(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_VENUE',
      payload:null
    })
  }
}
export function getVenueDetails(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_DETAILS',
      payload:axios.get(`venue/details/${id}`)
    })
  }
}
export function updateVenueDetails(id, data){
  const venueData = {
    name: data.name,
    login_hash: data.loginHash,
    contact_name: data.contactName,
    contact_number: data.contactNumber,
    address_line_1: data.addressLine1,
    address_line_2: data.addressLine2 || '',
    town: data.town,
    postcode: data.postcode,
    settings: {
      file_size: data.fileSize
    }
  }

  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_DETAILS',
      payload:axios.post(`venue/details/${id}`, venueData)
    })
  }
}
export function getVenuePlaylists(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_PLAYLISTS',
      payload:axios.get(`venue/playlists/${id}`)
    })
  }
}

export function updateVenuePlaylistSettings(playlist,venue_id){
  const active = playlist.active === 2 ? 1 : 2
  return (dispatch) => {
    return dispatch({
      type:'PUT_VENUE_PLAYLISTS_SETTINGS',
      payload:axios.put(`venue/playlist/settings`,{
        playlist_id:playlist.id,
        venue_id:venue_id,
        active:active
      }),
      meta:{
        playlist_id:playlist.id,
        active:active
      }
    })
  }
}

export function getVenueSchedule(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_SCHEDULE',
      payload:axios.get(`venue/schedule/${id}`)
    })
  }
}
export function createVenueSchedule(playlistData, playlistIndex){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE_SCHEDULE',
      payload:axios.post(`venue/schedule/create`, playlistData),
      meta: playlistIndex
    })
  }
}
export function updateVenueSchedule(id, playlistData){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_SCHEDULE',
      payload:axios.post(`venue/schedule/update/${id}`, playlistData)
    })
  }
}
export function deleteVenueSchedule(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_VENUE_SCHEDULE',
      payload:axios.delete(`venue/schedule/${id}`)
    })
  }
}
export function addInterleave(data, index,businessID){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE_INTERLEAVE',
      payload:axios.post(`venue/schedule/interleave`, data),
      meta:index
    })
  }
}
export function addInterleaves(data){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE_INTERLEAVES',
      payload:axios.post(`venue/schedule/interleaves`, data)
    })
  }
}
export function deleteInterleave(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_VENUE_INTERLEAVE',
      payload:axios.delete(`venue/schedule/interleave/${id}`)
    })
  }
}
export function updateInterleaveRatios(data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_INTERLEAVE_RATIOS',
      payload:axios.post(`venue/schedule/interleave/update/ratio`,data)
    })
  }
}
export function toggleInterleaveRatioChanger(isOpen){
  return (dispatch) => {
    return dispatch({
      type:'TOGGLE_VENUE_INTERLEAVE_RATIO_CHANGER',
      payload:isOpen
    })
  }
}
export function copyInterleaveToVenues(data){
  return (dispatch) => {
    return dispatch({
      type:'COPY_VENUE_INTERLEAVES',
      payload:axios.post(`venue/schedule/interleave/copy`,data)
    })
  }
}
export function copySchedulesToVenues(data){
  return (dispatch) => {
    return dispatch({
      type:'COPY_VENUE_SCHEDULES',
      payload:axios.post(`venue/schedule/copy`,data)
    })
  }
}
export function copyCalendarToVenues(data){
  return (dispatch) => {
    return dispatch({
      type: 'COPY_CALENDAR_TO_VENUES',
      payload: axios.post(`/venue/schedule/calendar/copy`,data)
    })
  }
}
export function copyCalendarInterleaveToVenues(data){
  return (dispatch) => {
    return dispatch({
      type: 'COPY_CALENDAR_INTERLEAVE_TO_VENUES',
      payload: axios.post(`/venue/schedule/calendar-interleave/copy`,data)
    })
  }
}
export function getVenueActions(venue_id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_ACTIONS',
      payload:axios.get(`venue/actions/${venue_id}`)
    })
  }
}
export function toggleScheduleCreator(isOpen){
  return (dispatch) => {
    dispatch({type:'TOGGLE_SCHEDULE_CREATOR',payload:isOpen})
  }
}
export function setPlaylistInfo(isOpen){
  return (dispatch) => {
    dispatch({type:'SET_PLAYLIST_INFO',payload:isOpen})
  }
}
export function getVenueCalendar(id, date){
  return (dispatch) => {
    cancel && cancel('FETCH_VENUE_CALENDAR request cancelled')

    const request = axios.get(`venue/schedule/${id}/calendar/${date}`,
      {cancelToken: new CancelToken(function executor(c) {
          cancel = c
        })
      }).catch((cancel) => {
      console.log(cancel)
      return 'cancelled'
    })
    return dispatch({
      type:'FETCH_VENUE_CALENDAR',
      payload: request
    })
  }
}
export function createVenueCalendar(playlistData, playlistIndex){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE_CALENDAR',
      payload:axios.post(`venue/schedule/calendar/create`, playlistData),
      meta: playlistIndex
    })
  }
}
export function updateVenueCalendar(id, playlistData){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_CALENDAR',
      payload:axios.post(`venue/schedule/calendar/update/${id}`, playlistData)
    })
  }
}
export function deleteVenueCalendar(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_VENUE_CALENDAR',
      payload:axios.delete(`venue/schedule/calendar/${id}`)
    })
  }
}
export function addCalendarInterleave(data, index,businessID){
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_CALENDAR_INTERLEAVE',
      payload: axios.post(`venue/schedule/calendar/interleave`, data),
      meta: index
    })
  }
}
export function addCalendarInterleaves(data){
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_CALENDAR_INTERLEAVES',
      payload: axios.post(`venue/schedule/calendar/interleaves`, data)
    })
  }
}
export function deleteCalendarInterleave(id){
  return (dispatch) => {
    return dispatch({
      type: 'DELETE_CALENDAR_INTERLEAVE',
      payload: axios.delete(`venue/schedule/calendar/interleave/${id}`)
    })
  }
}
export function updateCalendarInterleaveRatios(data){
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_CALENDAR_INTERLEAVE_RATIOS',
      payload: axios.post(`venue/schedule/calendar/interleave/update/ratio`,data)
    })
  }
}
export function createSavedCalendarItem(playlistData, playlistIndex){
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_SAVED_CALENDAR_ITEM',
      payload: axios.post(`venue/schedule/calendar/saved/item/create`, playlistData),
      meta: playlistIndex
    })
  }
}
export function updateSavedCalendarItem(id, playlistData){
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_SAVED_CALENDAR_ITEM',
      payload: axios.post(`venue/schedule/calendar/saved/item/update/${id}`, playlistData)
    })
  }
}
export function deleteSavedCalendarItem(id){
  return (dispatch) => {
    return dispatch({
      type: 'DELETE_SAVED_CALENDAR_ITEM',
      payload: axios.delete(`venue/schedule/calendar/saved/item/${id}`)
    })
  }
}
export function getSavedCalendars(venue_id){
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_SAVED_CALENDARS',
      payload: axios.get(`/venue/schedule/calendar/saved/${venue_id}`)
    })
  }
}
export function loadSavedCalender(saved_id,venue_id){
  const data = {
    saved_id: saved_id,
    venue_id: venue_id
  }
  return (dispatch) => {
    return dispatch({
      type: 'LOAD_SAVED_CALENDAR',
      payload: axios.post(`/venue/schedule/calendar/saved/load`,data),
      meta: saved_id
    })
  }
}

// This is providing the same functionality as loadSavedCalender,
// other than the change in loading state in the reducer: loading vs scheduleLoading.
// This one is used when switching between schedule and interleave,
// rather than loading a saved calendar from scratch.

export function fetchSavedCalender(saved_id,venue_id){
  const data = {
    saved_id: saved_id,
    venue_id: venue_id
  }
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_SAVED_CALENDAR',
      payload: axios.post(`/venue/schedule/calendar/saved/load`,data),
      meta: saved_id
    })
  }
}
export function createSavedCalendar(venue_id,name,templateType,saved_id){
  const data = {
    venue_id: venue_id,
    name: name,
    template_type: templateType,
    saved_id: saved_id
  }
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_SAVED_CALENDAR',
      payload: axios.post(`/venue/schedule/calendar/saved/create`,data)
    })
  }
}
export function resetSavedCalendarGenerator() {
  return (dispatch) => {
    return dispatch({
      type: 'RESET_SAVED_CALENDAR_GENERATOR',
      payload: true
    })
  }
}
export function addSavedCalendarToCalendar(data){
  return (dispatch) => {
    return dispatch({
      type: 'ADD_SAVED_CALENDAR_TO_CALENDAR',
      payload: axios.post(`/venue/schedule/calendar/saved/add`,data)
    })
  }
}
export function addSavedCalendarInterleave(data, index,businessID){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_SAVED_CALENDAR_INTERLEAVE',
      payload:axios.post(`venue/schedule/calendar/saved/interleave`, data),
      meta:index
    })
  }
}
export function addSavedCalendarInterleaves(data){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_SAVED_CALENDAR_INTERLEAVES',
      payload:axios.post(`venue/schedule/calendar/saved/interleaves`, data)
    })
  }
}
export function deleteSavedCalendarInterleave(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_SAVED_CALENDAR_INTERLEAVE',
      payload:axios.delete(`venue/schedule/calendar/saved/interleave/${id}`)
    })
  }
}
export function updateSavedCalendarInterleaveRatios(data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_SAVED_CALENDAR_INTERLEAVE_RATIOS',
      payload:axios.post(`venue/schedule/calendar/saved/interleave/update/ratio`,data)
    })
  }
}
export function getVenueAnnouncements(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_ANNOUNCEMENTS',
      payload:axios.get(`venue/announcements/${id}`)
    })
  }
}
export function updateAnnouncement(time,id){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_ANNOUNCEMENTS',
      payload:axios.put(`venue/announcements/${id}`,{time:time}),
      meta:{id:id,time:time}
    })
  }
}
export function createAnnouncements(data){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE_ANNOUNCEMENTS',
      payload:axios.post(`venue/announcements`,data)
    })
  }
}
export function copyAnnouncementsToVenues(data){
  return (dispatch) => {
    return dispatch({
      type:'COPY_VENUE_ANNOUNCEMENTS',
      payload:axios.post(`venue/announcements/copy`,data)
    })
  }
}
export function deleteAnnouncement(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_VENUE_ANNOUNCEMENT',
      payload:axios.delete(`venue/announcement/${id}`),
      meta:id
    })
  }
}
export function deleteAnnouncements(venue_id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_VENUE_ANNOUNCEMENTS',
      payload:axios.delete(`venue/announcements/${venue_id}`)
    })
  }
}
export function createVenue(data){
  const venueData = {
    name: data.venueName,
    business_id: data.business.id,
    contact_name: data.contactName,
    contact_number: data.contactNumber,
    address_line_1: data.address1,
    address_line_2: data.address2 || '',
    town: data.town || '',
    postcode: data.postcode,
    username: data.username,
    password: data.password,
    confirm_password: data.confirmPassword,
  }

  const storedVenueData = data
  delete storedVenueData['password']
  delete storedVenueData['confirmPassword']

  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE',
      payload:axios.post(`venue/create`, venueData),
      meta: storedVenueData
    })
  }
}
export function deleteVenue(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_VENUE',
      payload:axios.delete(`venue/${id}`),
      meta:id
    })
  }
}
export function copyVenue(id,data){
  const venueData = {
    name: data.venueName,
    business_id: data.business.id,
    username: data.username,
    password: data.password,
    confirm_password: data.confirmPassword,
  }

  const storedVenueData = data
  delete storedVenueData['password']
  delete storedVenueData['confirmPassword']

  return (dispatch) => {
    return dispatch({
      type:'COPY_VENUE',
      payload:axios.post(`venue/copy/${id}`,venueData),
      meta: storedVenueData
    })
  }
}
export function addPlaylistToVenue(data,playlist){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_TO_VENUE',
      payload:axios.post(`venue/playlist/add`,data),
      meta:playlist
    })
  }
}
export function removePlaylistFromVenue(venueID,playlist){
  return (dispatch) => {
    return dispatch({
      type:'REMOVE_PLAYLIST_FROM_VENUE',
      payload:axios.delete(`venue/playlist/remove/${venueID}/${playlist.id}`),
      meta:playlist
    })
  }
}
export function getVenueControls(id){
  return (dispatch) => {
    if(ws){
      ws.endConnection()
    }
    return dispatch({
      type:'FETCH_VENUE_CONTROLS',
      payload:axios.get(`/manager/venue/${id}`)
    })
  }
}
export function openManagerWs(){
  return (dispatch) => {
    ws = new Websockets(dispatch)
    ws.init()
    dispatch({type:'WS_MANAGER_INIT',payload:{init:true}})
  }
}

export function openVenueWS(token){
  return (dispatch) => {
    ws = new Websockets(dispatch)
    ws.init(token)
    dispatch({type:'WS_INIT',payload:{init:true}})
  }
}
export function connectVenueWS(){
  return () => {
    ws.connect()
  }
}
export function connectManagerWS(){
  return () => {
    ws.connect()
  }
}
export function closeWSConnection(){
  return (dispatch) => {
    ws.endConnection()
    dispatch({type:'WS_RESET',payload:null})
  }
}
export function toggleEnergySlider(isActive){
  ws.send('energyMan',{status:isActive,level:5})
  return {
    type:'TOGGLE_ENERGY_SLIDER',
    payload:isActive
  }
}

export function updateVenueEnergy(energy){
  ws.send('energyMan',{status:true,level:energy})
  return {
    type:'UPDATE_VENUE_ENERGY',
    payload:energy
  }
}

export function setVolume(vol){
  ws.send('volume',vol)
  return {
    type:'WS_SET_VOLUME',
    payload:vol
  }
}

export function getVenueOnline(token){
  return () => {
    ws.sendManager('checkPlOnlineMM',token,null)
  }
}

export function skipBack(){
  return () => {
    ws.send('skipBack',null)
  }
}

export function skipForward(){
  return () => {
    ws.send('skipForward',null)
  }
}

export function playPause(isPlaying){
  ws.send('playPause',null)
  return {
    type:'VENUE_ISPLAYING',
    payload:isPlaying
  }
}

export function toggleFullscreen(isFullscreen){
  return {
    type:'VENUE_TOGGLE_FULLSCREEN',
    payload:isFullscreen
  }
}

export function selectPtp(data){
  return () => {
    ws.send('pushToPlay',data)
  }
}

export function cancelPtp(){
  return () => {
    ws.send('cancelPushToPlay',null)
  }
}
export function restartPlayer(){
  return () => {
    ws.send('restartPlayer',null)
  }
}
export function getSavedSchedules(venue_id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_SAVED_SCHEDULES',
      payload:axios.get(`/venue/schedule/saved/${venue_id}`)
    })
  }
}
export function loadSchedule(saved_id,venue_id){
  const data = {
    saved_id:saved_id,
    venue_id:venue_id
  }
  return (dispatch) => {
    return dispatch({
      type:'LOAD_SCHEDULE',
      payload:axios.post(`/venue/schedule/load`,data)
    })
  }
}
export function saveSchedule(venue_id,name){
  const data = {
    venue_id:venue_id,
    name:name
  }
  return (dispatch) => {
    return dispatch({
      type:'SAVE_SCHEDULE',
      payload:axios.post(`/venue/schedule/save`,data)
    })
  }
}
export function clearBusinessVenues(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_BUSINESS_VENUES',
      payload: null
    })
  }
}
export function getVenuesByBusiness(data){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUES_BY_BUSINESS',
      payload:axios.get(`venues/business/${data.id}`),
      meta: {
        name: data.name,
        id: data.id
      }
    })
  }
}
export function getVenueUser(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_USER',
      payload:axios.get(`venue/user/${id}`)
    })
  }
}
export function updateVenueUser(id,data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_USER',
      payload:axios.put(`venue/user/${id}`,data),
      meta:data
    })
  }
}

export function blockTrack(venueID, trackID){
  return (dispatch) => {
    return dispatch({
      type: 'BLOCK_TRACK',
      payload: axios.put(`/venue/block-track/${venueID}/${trackID}`),
      meta: trackID
    })
  }
}

export function getBlockedTracks(venue_id){
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_BLOCKED_TRACKS',
      payload: axios.get(`/venue/blocked/${venue_id}`)
    })
  }
}

export function removeBlockedTrack(blocked_id){
  return (dispatch) => {
    return dispatch({
      type: 'DELETE_BLOCKED_TRACKS',
      payload: axios.delete(`/venue/blocked/${blocked_id}`),
      meta:blocked_id
    })
  }
}

export function getVenueDevices(venue_id) {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_VENUE_DEVICES',
      payload: axios.get(`/venue/devices/${venue_id}`)
    })
  }
}

export function getVenueDeviceTypes() {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_VENUE_DEVICE_TYPES',
      payload: axios.get(`/venue/devices/types`)
    })
  }
}

export function createVenueDevice(venueID, data) {
  const venueDeviceData = {
    ...data,
    deviceType: parseInt(data.deviceType)
  }
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_VENUE_DEVICE',
      payload: axios.post(`/venue/devices/create/${venueID}`, venueDeviceData)
    })
  }
}

export function updateVenueDevice(venueID, data) {
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_VENUE_DEVICE',
      payload: axios.post(`/venue/devices/update/${venueID}`, data)
    })
  }
}

export function deleteVenueDevice(deviceID) {
  return (dispatch) => {
    return dispatch({
      type: 'DELETE_VENUE_DEVICE',
      payload: axios.delete(`/venue/devices/${deviceID}`),
      meta: deviceID
    })
  }
}

export function createVenueReplacementSD(venueID, data) {
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_VENUE_REPLACEMENT_SD',
      payload: axios.post(`/venue/devices/replacement/${venueID}`, data)
    })
  }
}

export function deleteReplacementSD(replacementID) {
  return (dispatch) => {
    return dispatch({
      type: 'DELETE_VENUE_REPLACEMENT_SD',
      payload: axios.delete(`/venue/devices/replacement/${replacementID}`),
      meta: replacementID
    })
  }
}
