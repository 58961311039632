const initialState = {
  details:{
    id:null
  },
  tracks:[],
  totalTracks:0,
  blockedTracks: [],
  blockedTracksLoading: [],
  playlists:[],
  schedules:[],
  saved_schedules:[],
  saveScheduleLoaded:false,
  saved_calendar_schedules: [],
  saveCalendarScheduleLoaded: false,
  saved_id: null,
  schedule_template: [],
  announcements:[],
  loading:false,
  announcementsLoading:false,
  announcementsCreated:false,
  announcementsDeleted:false,
  loaded:false,
  scheduleLoading:false,
  venueDetailsLoading: false,
  error:null,
  newPlaylistID: null,
  newInterleaveID: null,
  newInterleaveIDs: [],
  showScheduleCreator: false,
  activePlaylistInfo: null,
  businessID:null,
  new_template_type: 'active',
  calendarScheduleName: '',
  devices: [],
  deviceTypes: [],
  warrantyTypes: [],
  connectionTypes: [],
  venueHardwareLoading: false,
  overlayVenueHardwareLoading: false,
  venueReplacementLoading: []
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'APP_BODY_CLICK':{
      state = {...state,showScheduleCreator:false,activePlaylistInfo:null}
      break
    }
    case 'TOGGLE_SCHEDULE_CREATOR':{
      state = {...state, showScheduleCreator:action.payload,activePlaylistInfo:null}
      break
    }
    case 'TOGGLE_VENUE_INTERLEAVE_RATIO_CHANGER':{
      state = {
        ...state,
        showInterleaveRatioChanger:action.payload
      }
      break
    }
    case 'SET_PLAYLIST_INFO':{
      state = {...state, activePlaylistInfo:action.payload,showScheduleCreator:false}
      break
    }
    case 'CLEAR_VENUE':{
      state = {...state,details:{},tracks:[],playlists:[],schedules:[],announcements:[]}
      break
    }
    case 'FETCH_VENUE_TRACKS_PENDING':{
      state = {...state,loading:true,tracks:[]}
      break
    }
    case 'FETCH_VENUE_TRACKS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_TRACKS_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracks:action.payload.data.tracks
      }
      break
    }
    case 'FETCH_VENUE_VENUES_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_VENUE_VENUES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_VENUES_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        venues:state.venues.concat(action.payload.data.venues)
      }
      break
    }
    case 'FETCH_VENUE_SCHEDULE_PENDING':{
      state = {
        ...state,
        scheduleLoading:true,
        newInterleaveIDs: []
      }
      break
    }
    case 'FETCH_VENUE_SCHEDULE_REJECTED':{
      state = {...state,scheduleLoading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_SCHEDULE_FULFILLED':{
      state =  {
        ...state,
        scheduleLoading:false,
        loaded:true,
        schedules:action.payload.data.schedule_template,
        playlists:action.payload.data.playlists,
        businessID:action.payload.data.business_id,
        venueID:action.payload.data.id
      }
      break
    }
    case 'CREATE_VENUE_SCHEDULE_FULFILLED':{
        state = {
        ...state,
        newPlaylistID: {
          index: action.meta,
          id: action.payload.data.res.data.id
        }
      }
      break
    }
    case 'UPDATE_VENUE_SCHEDULE_FULFILLED':{
      state = {
        ...state
      }
      break
    }
    case 'DELETE_VENUE_SCHEDULE_FULFILLED':{
      state = {
        ...state
      }
      break
    }
    case 'CREATE_VENUE_INTERLEAVE_FULFILLED':{
      state = {
        ...state,
        newInterleaveID: {
          index: `${action.meta}` ,
          id: action.payload.data.id
        }
      }
      break
    }
    case 'CREATE_VENUE_INTERLEAVES_FULFILLED':{
      state = {
        ...state,
        newInterleaveIDs: action.payload.data
      }
      break
    }
    case 'UPDATE_VENUE_INTERLEAVE_RATIOS_FULFILLED':{
      state = {
        ...state,
        newInterleaveIDs: action.payload.data
      }
      break
    }
    case 'DELETE_VENUE_INTERLEAVE_FULFILLED':{
      state = {
        ...state
      }
      break
    }
    case 'FETCH_VENUE_CALENDAR_PENDING':{
      state = {...state,scheduleLoading:true}
      break
    }
    case 'FETCH_VENUE_CALENDAR_REJECTED':{
      state = {...state,scheduleLoading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_CALENDAR_FULFILLED':{
      // if the request has been cancelled by another request,
      // set the loading state to true
      if (action.payload === 'cancelled') {
        state = {
          ...state,
          scheduleLoading:true
        }
        break
      }

      state =  {
        ...state,
        scheduleLoading: false,
        loaded: true,
        schedules: action.payload.data.schedule_calendar,
        schedule_template: action.payload.data.schedule_template,
        playlists: action.payload.data.playlists,
        businessID: action.payload.data.business_id
      }
      break
    }
    case 'CREATE_VENUE_CALENDAR_FULFILLED':{
      state = {
        ...state,
        newPlaylistID: {
          index: action.meta,
          id: action.payload.data.id
        }
      }
      break
    }
    case 'CREATE_CALENDAR_INTERLEAVE_FULFILLED':{
      state = {
        ...state,
        newInterleaveID: {
          index: `${action.meta}` ,
          id: action.payload.data.id
        }
      }
      break
    }
    case 'CREATE_CALENDAR_INTERLEAVES_FULFILLED':{
      state = {
        ...state,
        newInterleaveIDs: action.payload.data,
      }
      break
    }
    case 'UPDATE_CALENDAR_INTERLEAVE_RATIOS_FULFILLED':{
      state = {
        ...state,
        newInterleaveIDs: action.payload.data
      }
      break
    }
    case 'FETCH_VENUE_ANNOUNCEMENTS_PENDING':{
      state = {
        ...state,
        announcementsLoading:true,
        announcements:[],
        playlists:[]
      }
      break
    }
    case 'FETCH_VENUE_ANNOUNCEMENTS_REJECTED':{
      state = {...state,announcementsLoading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_ANNOUNCEMENTS_FULFILLED':{
      state =  {
        ...state,
        announcementsLoading:false,
        loaded:true,
        announcements:action.payload.data.schedule_template,
        playlists:action.payload.data.playlists,
      }
      break
    }
    case 'UPDATE_VENUE_ANNOUNCEMENTS_FULFILLED':{

      const announcements = state.announcements.map(announcement => {
        if(announcement.pivot.id === action.meta.id){
          announcement.pivot.start = action.meta.time
        }
        return announcement
      })
      state =  {
        ...state,
        announcements:announcements
      }
      break
    }
    case 'CREATE_VENUE_ANNOUNCEMENTS_PENDING':{
      state = {
        ...state,
        announcementsCreated:false,
        announcementsLoading:true
      }
      break
    }
    case 'CREATE_VENUE_ANNOUNCEMENTS_REJECTED':{
      state = {
        ...state,
        announcementsCreated:false,
        error:action.payload,
        announcementsLoading:false
      }
      break
    }
    case 'CREATE_VENUE_ANNOUNCEMENTS_FULFILLED':{
      state = {
        ...state,
        announcementsCreated:true,
        announcementsLoading:false
      }
      break
    }
    case 'DELETE_VENUE_ANNOUNCEMENTS_PENDING':{
      state = {
        ...state,
        announcementsDeleted:false,
        announcementsLoading:true
      }
      break
    }
    case 'DELETE_VENUE_ANNOUNCEMENTS_REJECTED':{
      state = {
        ...state,
        announcementsDeleted:false,
        error:action.payload,
        announcementsLoading:false
      }
      break
    }
    case 'DELETE_VENUE_ANNOUNCEMENTS_FULFILLED':{
      state = {
        ...state,
        announcementsDeleted:true,
        announcementsLoading:false
      }
      break
    }
    case 'DELETE_VENUE_ANNOUNCEMENT_FULFILLED':{
      state = {
        ...state,
        announcements:state.announcements.filter(announcement => announcement.pivot.id !== action.meta),
      }
      break
    }
    case 'COPY_VENUE_ANNOUNCEMENTS_PENDING':{
      state = {
        ...state,
        announcementsLoading:true
      }
      break
    }
    case 'COPY_VENUE_ANNOUNCEMENTS_FULFILLED':{
      state = {
        ...state,
        announcementsLoading:false
      }
      break
    }
    case 'FETCH_VENUE_DETAILS_PENDING':{
      state = {
        ...state,
        venueDetailsLoading:true
      }
      break
    }
    case 'FETCH_VENUE_DETAILS_REJECTED':{
      state = {
        ...state,
        venueDetailsLoading:false,
        error:action.payload
      }
      break
    }
    case 'FETCH_VENUE_DETAILS_FULFILLED':
    case 'UPDATE_VENUE_DETAILS_FULFILLED':{
      const {data} = action.payload
      state =  {
        ...state,
        venueDetailsLoading:false,
        loaded:true,
        details:{
          ...state.details,
          id:data.id,
          name:data.name,
          contactName:data.contact_name,
          contactNumber:data.contact_number,
          addressLine1:data.address_line_1,
          addressLine2:data.address_line_2,
          town:data.town,
          postcode:data.postcode,
          fileSize:data.settings ? data.settings.file_size : 0,
          businessID:data.business_id
        },
        venueID: data.id
      }
      break
    }
    case 'UPDATE_VENUE_DETAILS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'UPDATE_VENUE_DETAILS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_PLAYLISTS_PENDING':{
      state = {...state,loading:true,playlists:[]}
      break
    }
    case 'FETCH_VENUE_PLAYLISTS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_PLAYLISTS_FULFILLED':{
      let totalTracks = 0
      const playlists = action.payload.data.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name,
          tracksCount:playlist.tracks_count,
          energy:playlist.meta.energy,
          type:playlist.playlist_types.type,
          createdAt:playlist.created_at,
          active:playlist.venue_playlist_settings.length > 0 ? playlist.venue_playlist_settings[0].pivot.active : 1,
          isScheduled: playlist.is_in_schedule,
          ...playlist.parent_id && {parent_id: playlist.parent_id},
          ...playlist.parent_name && {parent_name: playlist.parent_name.name}
        }
        totalTracks+=playlist.tracks_count
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        playlists:playlists,
        totalTracks:totalTracks
      }
      break
    }
    case 'PUT_VENUE_PLAYLISTS_SETTINGS_PENDING':{
      break
    }
    case 'PUT_VENUE_PLAYLISTS_SETTINGS_REJECTED':{
      break
    }
    case 'PUT_VENUE_PLAYLISTS_SETTINGS_FULFILLED':{
      const playlists = state.playlists.map(playlist => {
        if(playlist.id === action.meta.playlist_id){
          playlist.active = action.meta.active
        }
        return playlist
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        playlists:playlists
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':{
      const data = action.payload.data
      const playlist = {
        id: data.id,
        name: data.name,
        tracksCount: data.tracks_count,
        energy: data.meta.energy,
        type: data.playlist_types.type,
        createdAt: data.created_at,
        active: data.venue_playlist_settings.length > 0 ? data.venue_playlist_settings[0].pivot.active : 1,
        isScheduled: data.is_in_schedule,
        ...data.parent_id && {parent_id: data.parent_id},
        ...data.parent_name && {parent_name: data.parent_name.name}
      }
      state = {
        ...state,
        playlists: state.playlists.concat(playlist)
      }
      break
    }
    case 'REMOVE_PLAYLIST_FROM_VENUE_FULFILLED':{
      state = {
        ...state,
        playlists:state.playlists.filter(playlist => playlist.id !== action.meta.id)
      }
      break
    }
    case 'FETCH_VENUE_CONTROLS_PENDING':{
      state = {...state,loading:true,loaded:false}
      break
    }
    case 'FETCH_VENUE_CONTROLS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_CONTROLS_FULFILLED':{
      state =  {...state,loading:false,loaded:true,data:{...action.payload.data}}
      break
    }
    case 'LOAD_SCHEDULE_PENDING':{
      state = {...state,loading:true,saveScheduleLoaded:false}
      break
    }
    case 'LOAD_SCHEDULE_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'LOAD_SCHEDULE_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        saveScheduleLoaded:true
      }
      break
    }
    case 'FETCH_SAVED_SCHEDULES_PENDING':{
      state = {
        ...state,
        loading:true,
        loaded:false,
        saved_schedules:[]
      }
      break
    }
    case 'FETCH_SAVED_SCHEDULES_REJECTED':{
      state = {
        ...state,
        loading:false,
        error:action.payload
      }
      break
    }
    case 'FETCH_SAVED_SCHEDULES_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        saved_schedules:action.payload.data
      }
      break
    }
    case 'CREATE_SAVED_CALENDAR_FULFILLED':{
      const data = action.payload.data
      state =  {
        ...state,
        saveCalendarScheduleLoaded: true,
        saved_id: data.saved_id,
        schedules: data.schedule_saved_calendar_template || [],
        playlists: data.playlists,
        businessID: data.business_id,
        venueID: data.id,
        calendarScheduleName: data.calendarScheduleName
      }
      break
    }
    case 'LOAD_SAVED_CALENDAR_PENDING':{
      state = {
        ...state,
        loading:true,
        saveScheduleLoaded:false
      }
      break
    }
    case 'LOAD_SAVED_CALENDAR_REJECTED':{
      state = {
        ...state,
        loading:false,
        error:action.payload
      }
      break
    }
    case 'LOAD_SAVED_CALENDAR_FULFILLED':{
      const data = action.payload.data

      state =  {
        ...state,
        loading: false,
        loaded: true,
        saveCalendarScheduleLoaded: true,
        saved_id: action.meta,
        schedules: data.schedule_saved_calendar_template,
        playlists: data.playlists,
        businessID: data.business_id,
        venueID: data.id,
        calendarScheduleName: data.calendarScheduleName
      }
      break
    }

    // This is providing the same functionality as LOAD_SAVED_CALENDAR,
    // other than the change in loading state: loading vs scheduleLoading.
    // This one is used when switching between schedule and interleave,
    // rather than loading a saved calendar from scratch.

    case 'FETCH_SAVED_CALENDAR_PENDING':{
      state = {
        ...state,
        scheduleLoading:true,
        saveScheduleLoaded:false
      }
      break
    }
    case 'FETCH_SAVED_CALENDAR_REJECTED':{
      state = {
        ...state,
        scheduleLoading:false,
        error:action.payload
      }
      break
    }
    case 'FETCH_SAVED_CALENDAR_FULFILLED':{
      const data = action.payload.data

      state =  {
        ...state,
        scheduleLoading: false,
        loaded: true,
        saveCalendarScheduleLoaded: true,
        saved_id: action.meta,
        schedules: data.schedule_saved_calendar_template,
        playlists: data.playlists,
        businessID: data.business_id,
        venueID: data.id,
        calendarScheduleName: data.calendarScheduleName
      }
      break
    }
    case 'FETCH_SAVED_CALENDARS_PENDING':{
      state = {
        ...state,
        loading: true,
        loaded: false,
        saved_calendar_schedules: []
      }
      break
    }
    case 'FETCH_SAVED_CALENDARS_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }
    case 'FETCH_SAVED_CALENDARS_FULFILLED':{
      state =  {
        ...state,
        loading: false,
        loaded: true,
        saved_calendar_schedules: action.payload.data
      }
      break
    }
    case 'CREATE_SAVED_CALENDAR_ITEM_FULFILLED':{
      state = {
        ...state,
        newPlaylistID: {
          index: action.meta,
          id: action.payload.data.res.data.id
        }
      }
      break
    }
    case 'CREATE_SAVED_CALENDAR_INTERLEAVE_FULFILLED':{
      state = {
        ...state,
        newInterleaveID: {
          index: `${action.meta}` ,
          id: action.payload.data.id
        },
        scheduleLoading: false
      }
      break
    }
    case 'CREATE_SAVED_CALENDAR_INTERLEAVES_FULFILLED':{
      state = {
        ...state,
        newInterleaveIDs: action.payload.data,
        scheduleLoading: false
      }
      break
    }
    case 'RESET_SAVED_CALENDAR_GENERATOR': {
      state =  {
        ...state,
        loading: false,
        loaded: true,
        saveCalendarScheduleLoaded: false,
        saved_id: null,
        schedules: [],
        calendarScheduleName: ''
      }
      break
    }
    case 'FETCH_VENUE_USER_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_VENUE_USER_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_USER_FULFILLED':{
      const {data} = action.payload

      state =  {
        ...state,
        loading:false,
        loaded:true,
        user:{
          ...state.user,
          id:data.id,
          name:data.name,
          username:data.user[0].username,
          loginHash:data.user[0].login_hash
        }
      }
      break
    }
    case 'UPDATE_VENUE_USER_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'UPDATE_VENUE_USER_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'UPDATE_VENUE_USER_FULFILLED':{
      const {data} = action.payload

      state =  {
        ...state,
        error:null,
        loading:false,
        loaded:true,
        user:{
          ...state.user,
          id:data.id,
          name:data.name,
          username:data.user[0].username,
          loginHash:data.user[0].login_hash
        }
      }
      break
    }
    case 'BLOCK_TRACK_PENDING':{
      state = {
        ...state,
        blockedTracksLoading: state.blockedTracksLoading.concat(action.meta)
      }
      break
    }
    case 'BLOCK_TRACK_REJECTED':{
      state = {
        ...state,
        blockedTracksLoading: state.blockedTracksLoading.filter(trackID => trackID !== action.meta),
        error:action.payload
      }
      break
    }
    case 'BLOCK_TRACK_FULFILLED':{
      state =  {
        ...state,
        blockedTracksLoading: state.blockedTracksLoading.filter(trackID => trackID !== action.meta),
        blockedTracks: state.blockedTracks.concat({track_id: parseInt(action.payload.data.blocked_track_id)})
      }
      break
    }
    case 'DELETE_BLOCKED_TRACKS_PENDING':{
      state = {
        ...state
      }
      break
    }
    case 'DELETE_BLOCKED_TRACKS_REJECTED':{
      state = {
        ...state,
        error:action.payload
      }
      break
    }
    case 'DELETE_BLOCKED_TRACKS_FULFILLED':{
      state =  {
        ...state,
        blockedTracks:state.blockedTracks.filter(track => track.blocked_id !== action.meta)
      }
      break
    }
    case 'FETCH_BLOCKED_TRACKS_PENDING':{
      state = {
        ...state,
        blockedTracks:[]
      }
      break
    }
    case 'FETCH_BLOCKED_TRACKS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BLOCKED_TRACKS_FULFILLED':{

      const tracks = action.payload.data.map((blocked)=>{
        const data = {
          blocked_id:blocked.id,
          id:blocked.track.id,
          title:blocked.track.title,
          artist:blocked.track.artist,
          album:blocked.track.album,
          genres:blocked.track.genres,
          total_length:blocked.track.total_length,
          energy:blocked.track.energy,
          bpm:blocked.track.bpm,
          added:blocked.created_at.standard,
          addedMysql:blocked.created_at.mysql,
        }
        return data
      })

      state =  {
        ...state,
        blockedTracks: tracks
      }
      break
    }
    case 'FETCH_VENUE_DEVICES_PENDING':
    case 'CREATE_VENUE_DEVICE_PENDING':
    case 'UPDATE_VENUE_DEVICE_PENDING':
    case 'CREATE_VENUE_REPLACEMENT_SD_PENDING':{
      state = {
        ...state,
        devices: [],
        venueHardwareLoading: true,
        overlayVenueHardwareLoading: true
      }
      break
    }
    case 'FETCH_VENUE_DEVICES_REJECTED':{
      state = {...state,venueHardwareLoading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_DEVICES_FULFILLED':
    case 'CREATE_VENUE_DEVICE_FULFILLED':
    case 'UPDATE_VENUE_DEVICE_FULFILLED':
    case 'CREATE_VENUE_REPLACEMENT_SD_FULFILLED':{
      let devices = action.payload.data.devices.map((device)=>{
        const data = {
          avInstaller: device.av_installer,
          connectionType: device.connection ? device.connection.id : null,
          connectionTypeName: device.connection ? device.connection.connection : null,
          created: device.created_at,
          id: device.id,
          macAddress: device.mac_address,
          deviceType: device.type.id,
          deviceTypeName: device.type ? device.type.device_type : null,
          settings: device.settings,
          shippingDate: device.date_shipped,
          streamURL: device.stream_url,
          warrantyType: device.warranty ? device.warranty.id : null,
          warrantyTypeName: device.warranty ? device.warranty.warranty : null,
          replacements: device.replacements
        }
        return data
      })

      devices.sort((a,b) => {
        return new Date(b.created) - new Date(a.created);
      });

      state =  {
        ...state,
        devices: devices,
        venueHardwareLoading: false,
        overlayVenueHardwareLoading: false
      }
      break
    }

    case 'FETCH_VENUE_DEVICE_TYPES_PENDING':{
      state = {
        ...state,
        overlayVenueHardwareLoading: true
      }
      break
    }

    case 'FETCH_VENUE_DEVICE_TYPES_REJECTED':{
      state = {...state,overlayVenueHardwareLoading:false,error:action.payload}
      break
    }

    case 'FETCH_VENUE_DEVICE_TYPES_FULFILLED':{
      const payloadData = action.payload.data

      const deviceTypes = payloadData.deviceTypes.map((device) => {
        const data = {
          display: device.device_type,
          value: device.id
        }
        return data
      })

      const warrantyTypes = payloadData.warrantyTypes.map((warranty) => {
        const data = {
          display: warranty.warranty,
          value: warranty.id
        }
        return data
      })

      const connectionTypes = payloadData.connectionTypes.map((connection) => {
        const data = {
          display: connection.connection,
          value: connection.id
        }
        return data
      })

      state =  {
        ...state,
        deviceTypes: deviceTypes,
        warrantyTypes: warrantyTypes,
        connectionTypes: connectionTypes,
        overlayVenueHardwareLoading: false
      }
      break
    }

    case 'CREATE_VENUE_DEVICE_REJECTED':
    case 'UPDATE_VENUE_DEVICE_REJECTED':
    case 'CREATE_VENUE_REPLACEMENT_SD_REJECTED':{
      state = {
        ...state,
        overlayVenueHardwareLoading: false,
        error: action.payload
      }
      break
    }

    case 'DELETE_VENUE_DEVICE_PENDING':{
      state = {
        ...state,
        venueHardwareLoading: true,
      }
      break
    }
    case 'DELETE_VENUE_DEVICE_REJECTED':{
      state = {
        ...state,
        venueHardwareLoading: false,
        error:action.payload
      }
      break
    }
    case 'DELETE_VENUE_DEVICE_FULFILLED':{
      state =  {
        ...state,
        devices: state.devices.filter(device => device.id !== action.meta),
        venueHardwareLoading: false
      }
      break
    }

    case 'DELETE_VENUE_REPLACEMENT_SD_PENDING':{
      state = {
        ...state,
        venueReplacementLoading: state.venueReplacementLoading.concat(action.meta)
      }
      break
    }
    case 'DELETE_VENUE_REPLACEMENT_SD_REJECTED':{
      state = {
        ...state,
        venueReplacementLoading: state.venueReplacementLoading.filter(replacement => replacement !== action.meta),
        error:action.payload
      }
      break
    }
    case 'DELETE_VENUE_REPLACEMENT_SD_FULFILLED':{
      const devices = state.devices.map(device => {
        return {
          ...device,
          replacements: device.replacements.filter((replacement) => replacement.id !== action.meta)
        }
      })

      state =  {
        ...state,
        devices: devices,
        venueReplacementLoading: state.venueReplacementLoading.filter(replacement => replacement !== action.meta)
      }
      break
    }

    case 'LOGOUT':{
      state = {
        details:{
          name:null
        },
        tracks:[],
        playists:[],
        loading:false,
        loaded:false,
        venueDetailsLoading:false,
        devices: [],
        deviceTypes: [],
        warrantyTypes: [],
        connectionTypes: [],
        venueHardwareLoading: false,
        overlayVenueHardwareLoading: false,
        venueReplacementLoading: [],
        error:null
      }
      break
    }
    default: // no default case
  }
  return state
}
