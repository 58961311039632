const TabsModel = {
  playlists:[
    {name:'Pinned',link:'pinned'},
    {name:'Recent',link:'recent'},
    {name:'Owned',link:'owned'},
    {name:'Admin',link:'admin'},
    {name:'Special',link:'special'},
    {name:'Child',link:'child'},
    //{name:'Curating',link:'curating'},
    {name:'Search',link:'search'},
    {name:'Importer',link:'importer'},
    {name:'Create',link:'create'}
  ],
  overlayPlaylistsTracksAdd:[
    {name:'Assigned',link:'assigned'},
    {name:'Pinned',link:'pinned'},
    {name:'Recent',link:'recent'},
    {name:'Owned',link:'owned'},
    {name:'Search',link:'search'},
  ],
  overlayPlaylistsTracksAddMulti:[
    {name:'Pinned',link:'pinned'},
    {name:'Recent',link:'recent'},
    {name:'Owned',link:'owned'},
    {name:'Search',link:'search'}
  ],
  playlist:[
    {name:'Details',link:'details'},
    {name:'Tracks',link:'tracks'},
    {name:'Settings',link:'settings'},
    {name:'Tags',link:'tags'},
    {name:'Order',link:'order'},
    {name:'Library',link:'library'},
    {name:'Venues',link:'venues'},
    {name:'Children',link:'children'},
    {name:'Image',link:'image'},
    {name:'Curators',link:'curators'},
    //{name:'History',link:'history'}
  ],
  venues:[
    {name:'All',link:'all'},
    {name:'Create',link:'create'}
  ],
  venue:[
    {name:'Details',link:'details'},
    {name:'User',link:'user'},
    {name:'Playlists',link:'playlists'},
    {name:'Schedule',link:'schedule'},
    {name:'Calendar Generator',link:'calendarGenerator'},
    {name:'Announcements',link:'announcements'},
    {name:'Blocked tracks',link:'blocked'},
    //{name:'Managers',link:'managers'},
    {name:'Control',link:'control'},
    {name:'Activity',link:'activity'},
    //{name:'Settings',link:'settings'},
    {name:'Hardware',link:'hardware'},
    //{name:'History',link:'history'},
    //{name:'Favourites',link:'favourites'}
  ],
  library:[
    {name:'All',link:'all'},
    //{name:'Favourites',link:'favourites'},
    //{name:'Queue',link:'queue'}
  ],
  businesses:[
    {name:'All',link:'all'},
    {name:'Create',link:'create'}
  ],
  business:[
    {name:'Details',link:'details'},
    {name:'Settings',link:'settings'},
    {name:'User',link:'user'},
    {name:'Venues',link:'venues'},
    {name:'Actions',link:'actions'},
    {name:'Reports',link:'reports'},
    {name:'Subscribed',link:'subscribed'},
  ],
  fitnessClasses:[
    {name:'Classes',link:'classes'},
    {name:'Create',link:'create'}
  ],
  fitnessClass:[
    {name:'Timeline',link:'timeline'},
    {name:'Venues',link:'venues'}
  ],
  users:[
    {name:'Managers',link:'managers'},
    {name:'Curators',link:'curators'},
    {name:'Create',link:'create'}
  ],
  user:[
    // {name:'Details',link:'details'},
    {name:'User',link:'user'},
    {name:'Venues',link:'venues'},
    {name:'Settings',link:'settings'}
  ],
}
export default TabsModel
