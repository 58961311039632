import React, { Component } from 'react'
import { connect } from 'react-redux'

import { openMessageOverlay } from 'store/actions/messageOverlayAction'

import {
  setVolume,
  skipBack,
  skipForward,
  playPause,
  restartPlayer
} from 'store/actions/venueAction'

import Icon from 'ui/Icon'
import Container from 'ui/Container'
import Loader from 'ui/Loader'

class VenueControls extends Component{

  constructor(props){
    super(props)
    this.state = {
      mousedown:false,
      position:null,
      thumb:{
        style:{
          right:'50%',
          marginLeft:'0px'
        }
      },
      track:{
        style:{
          backgroundImage: 'linear-gradient(to right, #f0b35b 48%, #f0b35b; 0%)'
        }
      }
    }
  }

  componentWillMount(){
    //set volume on mount
    let pos = (10 - this.props.volume) *10
    this.setState({position:pos},()=>{
      this.setTrackPos(pos)
      this.setViewPosition()
    })
  }

  componentWillReceiveProps(newProps){
    if(newProps.volume){
      let pos = (10 - newProps.volume) *10
      this.setState({position:pos},()=>{
        this.setTrackPos(pos)
        this.setViewPosition()
      })
    }
  }

  volumeMouseUp = (e) => {
    e.preventDefault()
    if(
      this.state.mousedown &&
      this.state.position <= 100 &&
      this.state.position >= 0
    ){
      this.setVolume()
    }
    this.setState({mousedown:false})
  }

  volumeTouchEnd = (e) => {
    if(
      this.state.mousedown &&
      this.state.position <= 100 &&
      this.state.position >= 0
    ){
      this.setVolume()
    }
    this.setState({mousedown:false})
  }

  volumeMouseDown = (e) => {
    e.preventDefault()
    if(
      e.nativeEvent.srcElement.id === 'volume-thumb' &&
      !this.state.mousedown
    ){
      this.setState({mousedown:true})
    }  else if(
      this.props.isActive &&
      e.nativeEvent.srcElement.id === 'volume-track-container' &&
      !this.state.mousedown
    ){
      //only move volume on track event
      this.setState({mousedown:true})
      this.volumeSetPos(e);
    }
  }

  volumeTouchStart = (e) => {
    if(
      e.nativeEvent.srcElement.id === 'volume-thumb' &&
      !this.state.mousedown
    ){
      this.setState({mousedown:true})
    }
  }

  volumeMouseMove = (e) => {
    e.preventDefault()
    if(
      this.state.mousedown &&
      e.nativeEvent.srcElement.id === 'volume-track-container'
    ){
      this.volSetPos(e);
    }
  }
  volumeTouchMove = (e) => {
    if(
      this.state.mousedown &&
      e.nativeEvent.srcElement.id === 'volume-thumb'
    ){
      this.volSetPosTouch(e);
    }
  }

  volSetPos = (e) => {

    const clientWidth = e.nativeEvent.srcElement.clientWidth
    const offsetX = e.nativeEvent.offsetX

    var pos = Math.round(
      ((clientWidth - offsetX)/clientWidth)
      *100)
    var volPos = (pos < 10  ? 0 : pos > 100 ? 100 : Math.ceil(pos / 10) * 10)
    this.setTrackPos(volPos)
    this.setState({position:volPos})
    this.setViewPosition()
  }

  volSetPosTouch = (e) => {

    const clientWidth = e.nativeEvent.srcElement.parentElement.clientWidth
    const offsetX = e.touches[0].pageX - e.nativeEvent.srcElement.parentElement.offsetLeft

    var pos = Math.round(
      ((clientWidth - offsetX)/clientWidth)
      *100)
    var volPos = (pos < 10  ? 0 : pos > 100 ? 100 : Math.ceil(pos / 10) * 10)
    this.setTrackPos(volPos)
    this.setState({position:volPos})
    this.setViewPosition()
  }

  setTrackPos(volPos){
    const bg = `linear-gradient(to right, #f0b35b ${100 - volPos}%, #364d5d 0%)`
    this.setState({track:{style:{backgroundImage:bg}}})
  }

  setVolume(){
    var vol = 10 - (this.state.position/10)
    setTimeout(()=>{
      this.props.dispatch(setVolume(vol))
      this.props.dispatch(openMessageOverlay('info',`Volume set to ${vol}`))
    },1000)
  }

  setViewPosition(){
    if(this.state.position <= 100 && this.state.position >= 0){
      const pos = this.state.position - 2
      this.setState({
        thumb:{
          style:{
            right:pos+'%'
          }
        }
      })
    }
  }

  skipBack(){
    this.props.dispatch(skipBack())
  }

  skipForward(){
    this.props.dispatch(skipForward())
  }

  playPause(){
    this.props.dispatch(playPause(!this.props.isPlaying))
  }

  restartPlayer(){
    this.props.dispatch(restartPlayer())
  }

  volumeDown(){

  }

  volumeUp(){

  }

  render(){

    if(this.props.hasPlayer && this.props.swapping){
      return (
        <Container classname="venue-controls">
          <Loader/>
        </Container>
      )
    } else {
      return (
        <Container classname="venue-controls" maxWidth column>
          <Container classname="play-controls">
            <Icon name="skip-backward" action={()=>this.skipBack()}/>
            <Icon classname="play-pause" name={this.props.isPlaying ? 'pause' : 'play'} action={()=>this.playPause()}/>
            <Icon name="skip-forward" action={()=>this.skipForward()}/>
          </Container>
          <Container classname="volume-wrapper">
            <Icon name="volume-low" action={()=>this.volumeDown()}/>
            <div
              id="volume-track-container"
              onMouseUp={this.volumeMouseUp}
              onMouseDown={this.volumeMouseDown}
              onMouseMove={this.volumeMouseMove}
              onTouchMove={this.volumeTouchMove}
              onTouchStart={this.volumeTouchStart}
              onTouchEnd={this.volumeTouchEnd}>
              <div id="volume-track" style={this.state.track.style}>
                <div
                  id="volume-thumb"
                  style={this.state.thumb.style}
                  onMouseMove={this.volumeMouseMove}>
                </div>
              </div>
            </div>
            <Icon name="volume-high" action={()=>this.volumeUp()}/>
          </Container>
          <div className="display-restart">Restart player <Icon name="refresh" action={()=>this.restartPlayer()}/></div>
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    volume:store.venueWS.volume,
    isPlaying:store.venueWS.isPlaying
  }
}
export default connect(mapStateToProps)(VenueControls)
