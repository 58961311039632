const TypeaheadModel = {
  playlists:{
    field:'name',
    link:'playlists',
    placeholder:'Search playlists...',
    debounce:300
  },
  playlistsSearch:{
    field:'name',
    link:'playlists',
    placeholder:'Search playlists by name, tags, genres...',
    debounce:100
  },
  playlistCurators:{
    field:'name',
    action:true,
    placeholder:'Search users...',
    debounce:100
  },
  overlayPlaylistsSearch:{
    field:'name',
    link:'playlists',
    placeholder:'Search playlists by name, tags, genres...',
    debounce:300
  },
  overlayPlaylistsTracksAddSearch:{
    field:'name',
    link:'playlists',
    placeholder:'Search playlists by name, tags, genres...',
    debounce:300
  },
  venues:{
    field:'name',
    link:'venues',
    placeholder:'Search venues with name, username, contact number, postcode...',
    debounce:300
  },
  venuePlaylists:{
    field:'name',
    action:true,
    placeholder:'Add a playlist...',
    debounce:300
  },
  createVenue:{
    field:'name',
    form: true,
    placeholder:'Search for business',
    debounce:300
  },
  overlayVenueCopy:{
    field:'name',
    form: true,
    placeholder:'Search for business',
    debounce:300
  },
  createPlaylist:{
    field:'name',
    form: true,
    placeholder:'Search for business',
    debounce:300
  },
  createImporterPlaylist:{
    field:'name',
    form: true,
    placeholder:'Search for business',
    debounce:300
  },
  createPlaylistAddVenues:{
    field:'name',
    form: true,
    placeholder:'Search for business',
    debounce:300
  },
  businesses:{
    field:'name',
    link:'businesses',
    placeholder:'Search businesses...',
    debounce:300
  },
  businessSubscribed:{
    field:'name',
    action:true,
    placeholder:'Add a playlist...',
    debounce:300
  },
  library:{
    field:'name',
    link:'business',
    placeholder:'Search library...',
    debounce:300
  },
  playlistsMain:{
    field:'name',
    link:'business',
    placeholder:'Search playlists...',
    debounce:300
  },
  overlayPlaylistAddToVenues:{
    field:'name',
    link:'venues',
    placeholder:'Search venues...',
    debounce:300
  },
  interleavePlaylists:{
    field:'name',
    action:true,
    placeholder:'Search playlists...',
    debounce:300
  },
  fitnessClasses:{
    field:'name',
    link:'class',
    placeholder:'Search classes...',
    debounce:300
  },
  overlayFitnessTimeline:{
    field:'name',
    action:true,
    placeholder:'Search playlists by name, tags, genres...',
    debounce:300
  },
  overlayFitnessVenues:{
    field:'name',
    action:true,
    placeholder:'Search venues...',
    debounce:300
  },
  users:{
    field:'name',
    link:'users',
    placeholder:'Search users...',
    debounce:300
  },
  userVenues:{
    field:'name',
    form: true,
    placeholder:'Search for business',
    debounce:300
  },
  createUser:{
    field:'name',
    form: true,
    placeholder:'Search for business',
    debounce:300
  },
  overlaySettingsPlaylist:{
    field:'name',
    action: true,
    placeholder:'Search for parent playlist',
    debounce:300
  },
  playlistSettingsOwner:{
    field:'username',
    action: true,
    placeholder:'Search for an owner',
    debounce:300
  },
  overlayPlaylistsMore:{
    field:'name',
    action: true,
    placeholder:'Search for playlist',
    debounce:300
  }
}

export default TypeaheadModel
