import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import TableModel from 'models/TableModel'

const PlaylistsImporterTableRowActions = (props) => {
  const {
    classname,
    id
  } = props

  return (
    <div>
      <Link to={`${TableModel[classname].viewLink}?tab=importer&id=${id}`}>
        <Icon name="eye"/>
      </Link>
    </div>
  )
}

export default PlaylistsImporterTableRowActions
