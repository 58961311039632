const initialState = {
  data:[],
  typeahead:[],
  typeaheadLoading: false,
  count:null,
  loading:false,
  loaded:false,
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'CLEAR_BUSINESSES':{
      state = {...state,data:[]}
      break
    }
    case 'FETCH_BUSINESSES_PENDING':{
      state = {
        ...state,
        loading:true
      }
      if(action.meta){
        state = {...state,data:[]}
      }
      break
    }
    case 'FETCH_BUSINESSES_REJECTED':{
      state = {
        ...state,
        loading:false,
        error:action.payload
      }
      break
    }
    case 'FETCH_BUSINESSES_FULFILLED':{
      const businesses = action.payload.data.businesses.map((business)=>{
        const data = {
          id:business.id,
          name:business.name,
          contact:business.contact_name,
          created_at:business.created_at.standard,
          venues_count:business.venues_count,
          state:business.status.status,
          lastLogin:business.users[0] && business.users[0].last_login.standard,
          playlistsCount:business.playlists_count
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data: action.meta ? businesses : state.data.concat(businesses),
        count:action.payload.data.count
      }
      break
    }
    case 'SEARCH_BUSINESSES_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'SEARCH_BUSINESSES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'SEARCH_BUSINESSES_FULFILLED':{
      const businesses = action.payload.data.businesses.map((business)=>{
        const data = {
          id:business.id,
          name:business.name,
          contact:business.contact_name,
          created_at:business.created_at.standard,
          venues_count:business.venues_count,
          state:business.status.status,
          lastLogin:business.users[0].last_login.standard,
          playlistsCount:business.playlists_count
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data: businesses,
        count:action.payload.data.count
      }
      break
    }
    case 'FETCH_BUSINESSES_TYPEAHEAD_PENDING':{
      state = {
        ...state,
        typeaheadLoading:true,
        typeahead:[]
      }
      break
    }
    case 'FETCH_BUSINESSES_TYPEAHEAD_REJECTED':{
      state = {
        ...state,
        typeaheadLoading:false,
        error:action.payload
      }
      break
    }
    case 'FETCH_BUSINESSES_TYPEAHEAD_FULFILLED':{
      state =  {
        ...state,
        typeahead:state.typeahead.concat(action.payload ? action.payload.data : []),
        typeaheadLoading:false,
      }
      break
    }
    case 'CLEAR_BUSINESSES_TYPEAHEAD':{
      state = {...state,typeahead:[]}
      break
    }
    case 'CREATE_BUSINESS_PENDING':{
      state = {
        ...state,
        loading: true,
        createdBusinessID: null
      }
      break
    }
    case 'CREATE_BUSINESS_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload,
        storedBusinessData: action.meta
      }
      break
    }
    case 'CREATE_BUSINESS_FULFILLED':{
      state = {
        ...state,
        loading: false,
        error: null,
        businessCreated: true,
        storedBusinessData: {},
        createdBusinessID: action.payload.data.res.data.id
      }
      break
    }
    case 'DELETE_BUSINESS_FULFILLED':{
      const businesses = state.data.filter(business => business.id !== action.meta)
      state = {
        ...state,
        data:businesses
      }
      break
    }
    default: // no default
  }
  return state
}
