import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistVenues,
  removeVenueFromPlaylist
} from 'store/actions/playlistAction'

import {
  addPlaylistToVenue,
} from 'store/actions/venueAction'

import {
  toggleOverlay
} from 'store/actions/overlayAction'

import Button from 'ui/Button'
import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'
import PlaylistVenuesTableRowActions from './playlist-venues/PlaylistVenuesTableRowActions'
import { tableSorter } from 'helpers/TableHelper'

const classname = 'playlistVenues'

class PlaylistVenues extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:'',
      sorter:{
        field:'added',
        direction:'desc'
      }
    }
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistVenues(this.props.id))
  }

  addPlaylistToVenue(playlist){
    const data = {
      venue_id: this.props.id,
      playlist_id: playlist.id,
      business_id: this.props.businessID
    }
    this.props.dispatch(addPlaylistToVenue(data,playlist))
  }

  removeVenue(venue){
    this.props.dispatch(removeVenueFromPlaylist(venue.id,this.props.id))
  }

  filterVenues(e){
    const text = e.target.value
    this.setState({filter:text})
  }

  sortTable(head){
    console.log(head)
    this.setState({
      sorter:head
    })
  }

  sortType(x,y){
    const {field, direction} = this.state.sorter
    return tableSorter(x,y,field,direction)
  }

  getVenues(){
    //sort tracks by date added to playlist and filter
    return this.props.playlistVenues
      .sort((x,y) => this.sortType(x,y))
      .filter(venue => {
        if(venue.name.toLowerCase().includes(this.state.filter)){
          return venue
        }
        return false
      })
  }

  getTable(){
    return (
      <TableWrapper
        classname={classname}
        data={this.getVenues()}
        loading={this.props.loading}
        sortTable={(head)=>this.sortTable(head)}
        rowActions={
          <PlaylistVenuesTableRowActions
            moreActions={(data)=>this.moreActions(data)}
            removeVenue={(data)=>this.removeVenue(data)}
            classname={classname}
            removeVenueFromPlaylistLoading={this.props.removeVenueFromPlaylistLoading}
          />
        }
      />
    )
  }

  toggleAddToVenuesOverlay() {
    const {
      dispatch
    } = this.props

    dispatch(toggleOverlay(true, {playlist_id: parseInt(this.props.id)}, 'playlistAddToVenues'))
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container height='100%' column>
          <div>
            <Button
              action={()=>this.toggleAddToVenuesOverlay()}
              name='Add to venue'
            />
          </div>
          {this.getTable()}
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    playlistVenues:store.playlist.venues,
    loading:store.playlist.loading,
    removeVenueFromPlaylistLoading: store.playlist.removeVenueFromPlaylistLoading
  }
}

export default connect(mapStateToProps)(PlaylistVenues)
