export default class Websockets{

  constructor(dispatch){
    this.dispatch = dispatch
    if(process.env.NODE_ENV === 'development'){
      //this.url = 'ws://localhost:5000'
      this.url = 'wss://ws.player.openearmusic.com'
    } else if(process.env.NODE_ENV === 'production'){
      this.url = 'wss://ws.player.openearmusic.com'
    }
  }

  init(token){
    this.socket = new WebSocket(this.url)
    this.socket.onopen = this.onOpen.bind(this);
    this.socket.onclose = this.onClose.bind(this);
    this.socket.onmessage = this.receive.bind(this);
    this.socket.onerror = this.error.bind(this);
    this.token = token
  }

  initManager(){
    this.socket = new WebSocket(this.url)
    this.socket.onopen = this.onOpen.bind(this);
    this.socket.onclose = this.onClose.bind(this);
    this.socket.onmessage = this.receive.bind(this);
    this.socket.onerror = this.error.bind(this);
    //this.token = token
  }

  onOpen(){
    this.dispatch({type:'WS_OPEN',payload:{open:true}})
    this.ping = setInterval(()=>{
      this.send('ping',null)
    },10000)
  }

  connect(){
    this.send('manInit',null)
  }

  onClose(){
    this.socket.close()
    // if the websocket connection disconnects, disable the player
    this.dispatch({type:'WS_NO_PLAYER'})
  }

  receive(res){
    let data = JSON.parse(res.data)
    this.dispatch({type:'WS_CONNECTED',payload:{connected:true}})
    switch(data.event){
      case 'ready':
        this.dispatch({type:'WS_READY',payload:{ready:true,swapping:false}})
        break;
      case 'swappingTracks':
        this.dispatch({type:'WS_SWAPPING_TRACKS',payload:{swapping:true}})
        break
      case 'currentTrack':
        this.dispatch({type:'WS_CURR_TRACK',payload:data.data})
        break
      case 'currentPlaylist':
        this.dispatch({type:'WS_CURR_PLAYLIST',payload:data.data})
        break
      case 'lastPlayed':
        this.dispatch({type:'WS_LAST_PLAYED',payload:data.data})
        break
      case 'playerRestarted':
        //reset ws
        this.dispatch({type:'WS_RESET',payload:data.data})
        break
      case 'volume':
        this.dispatch({type:'WS_SET_VOLUME',payload:data.data})
        break
      case 'downloading':
        this.dispatch({type:'WS_DOWNLOADING',payload:data.data})
        break
      case 'energyPl':
        this.dispatch({type:'WS_SET_ENERGY',payload:data.data})
        break
      case 'close':
        //handle ws socket close
        //this.dispatch({type:'WS_RESET',payload:data.data})
        //this.endConnection()
        window.location.reload()
        break
      case 'noPlayer':
        this.dispatch({type:'WS_NO_PLAYER',payload:data.data})
        break
      case 'alreadyConnected':
        //hmmm
        break
      case 'checkPlOnlineReturn':
        this.dispatch({type:'WS_PLAYER_ONLINE',payload:data.data})
        break
      //no default
    }
  }

  sendManager(event,token,data){
    this.socket.send(JSON.stringify({
      token:token,
      event:event,
      data:data
    }))
  }

  send(event,data,token = null){
    this.socket.send(JSON.stringify({
      token:token ?  token : this.token,
      event:event,
      data:data
    }))
  }

  error(err){
    console.log(err)
  }

  endConnection(){
    clearInterval(this.ping)
    this.ping = null
    if(this.socket){
      console.log('CLOSING')
      this.socket.close()
      this.dispatch({type:'WS_CLOSE',payload:{init:false,open:false,connected:false,ready:false}})
    }
  }

}
