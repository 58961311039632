import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  searchPlaylists,
  clearPlaylists,
  pinPlaylist,
  unpinPlaylist,
  playlistOverlaySearch,
  playlistOverlaySearchMulti,
  clearPlaylistTypeahead
} from 'store/actions/playlistAction'

import { openMessageOverlay } from 'store/actions/messageOverlayAction'
import { getRowLimit } from 'helpers/TableHelper'

import OverlayPlaylistsTracksAddTableRow from './OverlayPlaylistsTracksAddTableRow'
import OverlayPlaylistsTracksAddTableRowActions from './OverlayPlaylistsTracksAddTableRowActions'

import Loader from 'ui/Loader'
import Container from 'ui/Container'
import Typeahead from 'ui/Typeahead'
import TableWrapper from 'ui/TableWrapper'

const classname = 'overlayPlaylistsTracksAddSearch'

class OverlayPlaylistTracksAddSearch extends Component {

  constructor(props){
    super(props)
    this.state = {
      searchTerm:null
    }
  }

  componentDidMount(){
    this.props.dispatch(clearPlaylists())
    this.props.dispatch(clearPlaylistTypeahead())
  }

  typeaheadSearch(term){
    const {
      data,
      dispatch
    } = this.props

    if (data.track) {
      dispatch(playlistOverlaySearch(
        term,
        data.track.id
      ))
    } else {
      dispatch(playlistOverlaySearchMulti(
        term,
        data.id
      ))
    }
  }

  searchPlaylists(term){
    const {
      data,
      dispatch
    } = this.props

    const {
      searchTerm
    } = this.state

    dispatch(clearPlaylists())

    this.setState({
      searchTerm:term
    },()=>{
      if (this.props.data.track) {
        dispatch(playlistOverlaySearch(searchTerm, data.track.id))
      } else {
        dispatch(playlistOverlaySearchMulti(searchTerm, getRowLimit(classname), 0))
      }
    })
  }

  getPlaylists(limit,offset){
    this.props.dispatch(searchPlaylists(this.state.searchTerm,limit,offset))
  }

  clearTypeahead(){
    this.props.dispatch(clearPlaylistTypeahead())
  }

  pinPlaylist(data){
    this.props.dispatch(pinPlaylist(data.id))
    this.props.dispatch(openMessageOverlay('info',`Playlist pinned.`))
  }

  unpinPlaylist(data){
    const playlist = this.props.playlists.find(playlist => playlist.id === data.id)
    this.props.dispatch(unpinPlaylist(playlist))
    this.props.dispatch(openMessageOverlay('info',`Playlist unpinned.`))
  }

  clearPlaylists(){
    this.props.dispatch(clearPlaylists())
  }

  getTable(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return <TableWrapper
        classname={classname}
        data={this.props.playlists}
        count={this.props.count}
        clearData={()=>this.clearPlaylists()}
        getData={(limit,offset)=>this.getPlaylists(limit,offset)}
        loading={this.props.loading}
        rowComp={
          <OverlayPlaylistsTracksAddTableRow
            assignPlaylist={(playlist)=>this.props.assignPlaylist(playlist)}
            playlistID={this.props.data.playlistID}
          />
        }
        rowActions={
          <OverlayPlaylistsTracksAddTableRowActions
            addTrackToPlaylistLoading={this.props.addTrackToPlaylistLoading}
            classname={classname}
          />
        }/>
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth>
        <div className="playlists-search-typeahead-wrapper">
          <Typeahead
            typeaheadSearch={(term)=>this.typeaheadSearch(term)}
            search={(term)=>this.searchPlaylists(term)}
            clear={()=>this.clearTypeahead()}
            data={this.props.typeahead}
            classname={classname}
            hideDropdown/>
        </div>
        <Container height="100%" classname={`${classname}`} column>
          <Container height="100%">
            {this.getTable()}
          </Container>
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    playlists:store.playlists.data,
    typeahead:store.playlists.typeahead,
    count:store.playlists.count,
    loading:store.playlists.loading
  }
}

export default withRouter(connect(mapStateToProps)(OverlayPlaylistTracksAddSearch))

