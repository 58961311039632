import React, { Component } from 'react'

import TableModel from 'models/TableModel'
import { getRowLimit, setRowLimit, getRowOptions, getOffset } from 'helpers/TableHelper'

import Container from 'ui/Container'
import Table from 'ui/Table'
import TableHead from 'ui/TableHead'
import TableBody from 'ui/TableBody'
import TableRow from 'ui/TableRow'
import TableRowEmpty from 'ui/TableRowEmpty'
import TableRowLoading from 'ui/TableRowLoading'
import TableControls from 'ui/TableControls'

class TableWrapper extends Component {

  constructor(props){
    super(props)
    this.state = {
      typeaheadData:null,
      pageNo:1,
      totalPages:null,
      sorter:null,
      empty:true
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll,true)
  }

  componentDidUpdate(){
    const {data} = this.props
    if(this.props.controls){
      this.getTotalPages()
    }
    if(this.state.empty && data && data.length > 0){
      this.setState({empty:false})
    } else if(!this.state.empty && data && data.length === 0){
      this.setState({empty:true})
    }
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll,true)
  }

  setTableLimit(value){
    this.props.clearData()
    setRowLimit(this.props.classname,value)
    this.props.getData(getRowLimit(this.props.classname),0)
  }

  getTotalPages(){
    const rows = getRowLimit(this.props.classname)
    const totalPages = Math.ceil(this.props.count/rows)
    //stop state update if pages got
    if(totalPages &&
      (totalPages !== this.state.totalPages)
    ){
      this.setState({
        totalPages:totalPages,
        pageNo:1
      })
    }
  }

  sortTable(head){
    this.setState({sorter:head,pageNo:1},()=>{
      this.props.sortTable(head)
    })
  }

  selectAll(selected){
    this.props.selectAll(selected)
  }

  paginate(direction){
    let {pageNo, totalPages} = this.state
    //return if at start or end
    if(
      (pageNo === 1 && direction === 'back' ) ||
      (pageNo === totalPages && direction === 'forward')
    ){
      return
    }
    this.props.clearData()
    if(direction === 'back' && pageNo !== 1){
      pageNo--
      this.setState({pageNo:pageNo},()=>{
        this.props.getData(
          getRowLimit(this.props.classname),
          getOffset(getRowLimit(this.props.classname),pageNo),
          this.state.sorter
        )
      })
    } else if(direction === 'forward' && pageNo !== totalPages){
      pageNo++
      this.setState({pageNo:pageNo},()=>{
        this.props.getData(
          getRowLimit(this.props.classname),
          getOffset(getRowLimit(this.props.classname),pageNo),
          this.state.sorter
        )
      })
    }
  }
  //get the table header
  getTableHeader(){
    return null
  }

  getRowProps(row,index,length){
    //get the props that we need for the certain table actions
    switch(this.props.classname){
      case 'users':
        return { id: row.id, username:row.username }
      case 'venues':
        return { id: row.id, name:row.name }
      case 'businesses':
        return { id: row.id, name:row.name }
      case 'playlists':
        return { id: row.id, name:row.name, type:row.type, pinned:row.pinned_id, playlist:row }
      case 'playlistsSearch':
        return { id: row.id, name:row.name, type:row.type, pinned:row.pinned_id, playlist:row }
      case 'playlistsImporter':
        return { id: row.id }
      case 'playlistImporterTracks':
        return {
          id: row.id,
          artist: row.artist,
          title: row.title,
          total_length: row.total_length,
          importerMatchPerformed: row.importerMatchPerformed,
          tracks: row.tracks
        }
      case 'playlistTracksToImport':
        return {
          id: row.id
        }
      case 'playlistTracks':
        return { track:row,designation:row.designation,index:index}
      case 'playlistTracksSplit':
        return { track:row,designation:row.designation,index:index}
      case 'playlistTracksToApprove':
        return { track:row}
      case 'playlistOrder':
        return { track:row,length:length,index:index}
      case 'playlistVenues':
        return { venue:row}
      case 'playlistChildren':
        return { id:row.id}
      case 'library':
        return { track:row }
      case 'overlayPlaylistsTracksAdd':
        return { playlist:row, isTrackAssigned:row.isTrackAssigned }
      case 'overlayPlaylistsTracksAddMulti':
        return { playlist:row }
      case 'overlayPlaylistsSearch':
        return { playlist:row, isTrackAssigned:row.isTrackAssigned }
      case 'overlayPlaylistsTracksAddSearch':
        return { playlist:row, isTrackAssigned:row.isTrackAssigned }
      case 'overlayPlaylistAddToVenues':
        return { venue:row}
      case 'overlayPlaylistImporter':
        return { id: row.id, track: row }
      case 'venuePlaylists':
        return {id:row.id, playlist:row}
      case 'businessSubscribed':
        return {id:row.id, playlist:row}
      case 'businessVenues':
        return {id:row.id}
      case 'fitnessClasses':
        return {id:row.id}
      case 'fitnessClassVenues':
        return {id:row.id}
      case 'overlayFitnessVenues':
        return {id:row.id}
      case 'playlistCurators':
        return {id:row.id}
      case 'upload':
        return {
          id: row.id,
          album: row.album,
          artist: row.artist,
          length: row.length,
          title: row.title,
          importerMatchPerformed: row.importerMatchPerformed,
          tracks: row.tracks,
          fileName: row.fileName
        }
      case 'overlayUploadTracks':
        return { id: row.id, track: row }
      case 'venueBlocked':
        return { blocked_id: row.blocked_id, track: row }
      default: // no default case
    }
  }
  //return data list
  getRows(){
    const {data, loading, classname, playingTrack} = this.props
    if(data && data.length > 0){
      return data.map((row,index)=>{
        //add actions to row
        const actions = React.Children.map(this.props.rowActions, child =>
          //send length of array to row
          React.cloneElement(child, this.getRowProps(row,index,data.length))
        )
        //if has row set on props
        if(this.props.rowComp){
          //add default props
          const RowComp = React.cloneElement(this.props.rowComp,{
            key:index,
            index:index,
            data:row,
            classname:classname,
            actionsComp:actions
          })
          return RowComp
        } else {
          //set default row and add actions
          //find selected checkbox and return true/false if found, null if not
          return <TableRow
            userSelect={this.props.userSelect}
            play={typeof this.props.play === 'function' ? (id)=>this.props.play(id) : null}
            libraryAction={typeof this.props.libraryAction === 'function' ? (data)=>this.props.libraryAction(data) : null}
            checkboxAction={(e,id)=>this.props.checkboxAction(e,id)}
            selected={!this.props.checkboxSelected || this.props.checkboxSelected.find(elem => elem.id === row.id)}
            key={index}
            index={index}
            data={row}
            classname={this.props.classname}
            playingTrack={playingTrack}
            size={TableModel[this.props.classname]['actionSize']}>
            {actions}
          </TableRow>
        }
      })
    } else if(loading){
      return <TableRowLoading classname={this.props.classname}/>
    } else {
      return <TableRowEmpty classname={this.props.classname}/>
    }
  }

  getTableControls(){
    const {
      classname,
      loading
    } = this.props

    const {
      pageNo,
      totalPages
    } = this.state

    if(this.props.controls){
      return <TableControls
        className={classname}
        rowLimit={getRowLimit(classname)}
        rowOptions={getRowOptions(classname)}
        setTableLimit={(value)=>this.setTableLimit(value)}
        paginate={(direction)=>this.paginate(direction)}
        pageNo={pageNo}
        totalPages={totalPages}
        loading={loading}/>
    }
  }
  //infinite scroll
  handleScroll(e) {
    e.stopPropagation()
    if(this.props.controls &&
      e.srcElement.className.includes('container-table') &&
      !e.srcElement.className.includes('no-controls') &&
      e.srcElement.className.includes(this.props.classname)
    ){
      const elem = e.srcElement
      if(elem.scrollTop + elem.clientHeight >= elem.scrollHeight - 300 && !this.props.loading){
        let {pageNo, totalPages} = this.state
        if(pageNo !== totalPages){
          pageNo++
          this.setState({pageNo:pageNo},()=>{
            this.props.getData(
              getRowLimit(this.props.classname),
              getOffset(getRowLimit(this.props.classname),this.state.pageNo),
              this.state.sorter
            )
          })
        }
      }
    }
  }

  render(){
    const {classname, loading} = this.props
    return (
      <Container classname={`${classname}-table-wrapper`} column maxWidth controls={this.props.controls}>
        {this.props.tableAction}
        <Container classname={`table  ${loading ? 'loading':''} ${this.state.empty ? 'empty' : ''} ${classname}`} controls={this.props.controls}>
          <Table classname={classname}>
            <TableHead
              classname={classname}
              sortTable={(head)=>this.sortTable(head)}
              selectAll={(selected)=>this.selectAll(selected)}
              loading={this.props.loading}/>
            <TableBody classname={classname}>
              {this.getRows()}
            </TableBody>
          </Table>
          {this.getTableControls()}
        </Container>
      </Container>
    )
  }
}
export default TableWrapper
