import React, { Component } from 'react'
import { connect } from 'react-redux'

import { toggleOverlay } from 'store/actions/overlayAction'

import OverlayDefault from './overlay/OverlayDefault'
import OverlayBusinesses from './overlay/OverlayBusinesses'
import OverlayVenues from './overlay/OverlayVenues'
import OverlayUsers from './overlay/OverlayUsers'
import OverlayPlaylistsMore from './overlay/OverlayPlaylistsMore'
import OverlayPlaylistsTracksAdd from './overlay/OverlayPlaylistsTracksAdd'
import OverlayPlaylistsTracksAddMulti from './overlay/OverlayPlaylistsTracksAddMulti'
import OverlayPlaylistAddToVenues from './overlay/OverlayPlaylistAddToVenues'
import OverlayPlaylistImporter from './overlay/OverlayPlaylistImporter'
import OverlayPlaylistImporterCreatePlaylist from './overlay/OverlayPlaylistImporterCreatePlaylist'
import OverlayAnnouncements from './overlay/OverlayAnnouncements'
import OverlayPlaylistBreakdown from './overlay/OverlayPlaylistBreakdown'
import OverlayPlaylistTracksMore from './overlay/OverlayPlaylistTracksMore'
import OverlayVenueCopy from './overlay/OverlayVenueCopy'
import OverlayVenueInterleaveCopy from './overlay/OverlayVenueInterleaveCopy'
import OverlayVenueScheduleCopy from './overlay/OverlayVenueScheduleCopy'
import OverlayVenueCalendarCopy from './overlay/OverlayVenueCalendarCopy'
import OverlayVenueCalendarInterleaveCopy from './overlay/OverlayVenueCalendarInterleaveCopy'
import OverlayAnnouncementCopy from './overlay/OverlayAnnouncementCopy'
import OverlayVenueScheduleSave from './overlay/OverlayVenueScheduleSave'
import OverlayVenueSchedulesSaved from './overlay/OverlayVenueSchedulesSaved'
import OverlayVenueSavedCalendarCreate from './overlay/OverlayVenueSavedCalendarCreate'
import OverlayVenueSavedCalendars from './overlay/OverlayVenueSavedCalendars'
import OverlayVenueSavedCalendarAddToCalendar from './overlay/OverlayVenueSavedCalendarAddToCalendar'
import OverlayFitnessTimeline from './overlay/OverlayFitnessTimeline'
import OverlayFitnessVenues from './overlay/OverlayFitnessVenues'
import OverlayEditTrack from './overlay/OverlayEditTrack'
import OverlayUploadTracks from './overlay/OverlayUploadTracks'
import OverlayMultiEditTracks from './overlay/OverlayMultiEditTracks'
import OverlayVenueHardwareCreate from './overlay/OverlayVenueHardwareCreate'
import OverlayVenueHardwareUpdate from './overlay/OverlayVenueHardwareUpdate'
import OverlayVenueHardwareReplacementSD from './overlay/OverlayVenueHardwareReplacementSD'

import Container from 'ui/Container'
import Loader from 'ui/Loader'

class Overlay extends Component{

  constructor(props){
    super(props)
    this.overlayComps = {
      default:{comp:OverlayDefault,size:'normal'},
      businesses:{comp:OverlayBusinesses,size:'normal'},
      venues:{comp:OverlayVenues,size:'normal'},
      users:{comp:OverlayUsers,size:'small'},
      playlistsMore:{comp:OverlayPlaylistsMore,size:'normal'},
      announcements:{comp:OverlayAnnouncements,size:'normal'},
      breakdown:{comp:OverlayPlaylistBreakdown,size:'normal'},
      playlistsAdd:{comp:OverlayPlaylistsTracksAdd,size:'large'},
      multiPlaylistsAdd:{comp:OverlayPlaylistsTracksAddMulti,size:'large'},
      playlistTracksMore:{comp:OverlayPlaylistTracksMore,size:'normal'},
      playlistAddToVenues:{comp:OverlayPlaylistAddToVenues,size:'large'},
      playlistImporter:{comp:OverlayPlaylistImporter,size:'large'},
      playlistImporterCreatePlaylist:{comp:OverlayPlaylistImporterCreatePlaylist,size:'large'},
      venueCopy:{comp:OverlayVenueCopy,size:'tall'},
      venueInterleaveCopy:{comp:OverlayVenueInterleaveCopy,size:'tall'},
      venueScheduleCopy:{comp:OverlayVenueScheduleCopy,size:'tall'},
      venueCalendarCopy:{comp:OverlayVenueCalendarCopy,size:'tall'},
      venueCalendarInterleaveCopy:{comp:OverlayVenueCalendarInterleaveCopy,size:'tall'},
      venueAnnouncementCopy:{comp:OverlayAnnouncementCopy,size:'tall'},
      venueSchedulesSaved:{comp:OverlayVenueSchedulesSaved,size:'tall'},
      venueScheduleSave:{comp:OverlayVenueScheduleSave,size:'small'},
      venueSavedCalendarCreate:{comp:OverlayVenueSavedCalendarCreate,size:'auto'},
      venueSavedCalendars:{comp:OverlayVenueSavedCalendars,size:'tall'},
      venueSavedCalendarAddToCalendar:{comp:OverlayVenueSavedCalendarAddToCalendar,size:'auto'},
      fitnessTimeline:{comp:OverlayFitnessTimeline,size:'normal'},
      fitnessVenues:{comp:OverlayFitnessVenues,size:'normal'},
      editTrack:{comp:OverlayEditTrack,size:'large'},
      uploadTracks:{comp:OverlayUploadTracks,size:'large'},
      multiEditTracks:{comp:OverlayMultiEditTracks,size:'large'},
      venueHardwareCreate:{comp:OverlayVenueHardwareCreate,size:'large'},
      venueHardwareUpdate:{comp:OverlayVenueHardwareUpdate,size:'large'},
      venueHardwareReplacementSD:{comp:OverlayVenueHardwareReplacementSD,size:'normal'}
    }
  }

  closeOverlay(){
    this.props.dispatch(toggleOverlay(false))
  }

  stopPropogation(e){
    e.stopPropagation()
  }

  getOverlay(){
    if(this.props.loading){
      return <Loader/>
    } else {
      //create default comp for first load
      const Comp = this.overlayComps[this.props.overlay.type || 'default'].comp
      return <Comp data={this.props.overlay.data}/>
    }
  }

  render(){
    const {isActive, type} = this.props.overlay
    const className = `
      overlay-wrapper
      ${isActive ? 'active' : ''}
      ${this.overlayComps[type || 'default'].size}
    `
    return (
      <div className={className} onClick={()=>this.closeOverlay()}>
        <Container classname="overlay" action={(e)=>this.stopPropogation(e)}>
          {this.getOverlay()}
        </Container>
      </div>
    )
  }

}

function mapStateToProps(store){
  return {
    overlay:store.overlay,
    loading:store.overlay.loading
  }
}

export default connect(mapStateToProps)(Overlay)
