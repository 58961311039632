import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import queryString from 'qs'

import {
  getVenueControls,
  connectVenueWS,
  selectPtp,
  cancelPtp,
  openVenueWS,
  blockTrack,
  getBlockedTracks,
  closeWSConnection
} from 'store/actions/venueAction'

import { toggleConfirm } from 'store/actions/confirmAction'

import Playlists from 'modules/venue/control/VenuePlaylists'
import LastPlayed from 'modules/venue/control/VenueLastPlayed'
import Display from 'modules/venue/control/VenueDisplay'
import Controls from 'modules/venue/control/VenueControls'
import Energy from 'modules/venue/control/VenueEnergy'

import Loader from 'ui/Loader'
import Container from 'ui/Container'
import Confirm from 'ui/Confirm'
import Icon from 'ui/Icon'

const classname = 'venueControlsWrapper'

class VenueManager extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'controls'
    }
  }

  componentDidMount() {
    const {
      dispatch,
      id
    } = this.props

    console.log('mounted WS')
    dispatch(getVenueControls(id))
    dispatch(getBlockedTracks(id))
  }

  componentWillReceiveProps(newProps){
    //prevent infinite loop on new props
    //@TODO
    //this is causing the venue data to load when tabs are used in the venue feed
    if(newProps.location.pathname !== this.props.location.pathname){
      //get venue
      this.props.dispatch(getVenueControls(newProps.id))

    }
    if(!newProps.ws.init && newProps.loaded && newProps.id === this.props.id){
      this.props.dispatch(openVenueWS(newProps.venue.authToken))
    }
    //connect to ws after ws is open
    if(newProps.ws.init && newProps.ws.open && !newProps.ws.ready && !newProps.ws.connected){
      this.props.dispatch(connectVenueWS())
    }
    //get
    //console.log('FULLSCREEN',this.props.isFullscreen)
  }

  componentWillUnmount(){
    //close ws connection on unmount
    this.props.dispatch(closeWSConnection())
  }



  getTabQuery(){
    //grab query string and parse
    const query = queryString.parse(this.props.location.search.slice(1))
    //check current tab state and select if different
    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.selectTab(query.tab)
    }
  }

  selectTab(tab){
    //set tab state and query string on select
    this.setState({tab:tab},()=>{
      this.props.history.push({
        search: `?tab=${tab}`
      })
    })
  }

  setHeader(header){
    this.setState({header:header})
  }

  selectPtp(data){
    this.props.dispatch(selectPtp(data))
  }

  cancelPtp(){
    this.props.dispatch(cancelPtp())
  }

  likeTrack(track){
    //this.props.dispatch(toggleOverlay(true,`Track ${track.title}`,'Liked',1500))
  }

  blockTrack(track){
    const {
      dispatch,
      venue
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(blockTrack(venue.id, track.id))
      },
      question: `Are you sure you want to block the track ${track.title}?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  retry(){
    window.location.reload()
  }

  render(){
    if(this.props.loading){
      return <Loader />
    } else {
      const {
        hasPlayer,
        ws,
        venue
      } = this.props

      const isDisconnected = !(hasPlayer && ws.connected)

      return (
        <Container classname={classname} height="100%" maxWidth scroll>
          <Container
            classname={`venue-controls-wrapper venue-playlists ${isDisconnected ? 'venue-controls-wrapper--disabled' : ''}`}
            height="100%"
            column
          >
            <Playlists
              playlists={venue.playlists}
              vptp={venue.vptp}
              selectPtp={(data) => this.selectPtp(data)}
              swapping={ws.swapping}
            />
          </Container>
          <Container classname="venue-controls-wrapper venue-controls" height="100%" column>
            <h2>Controls</h2>
            <div className='venue-controls__player'>
              {isDisconnected ? (
                <div className='venue-controls__player-disabled'>
                  <p>Not connected.</p>
                  <p>Please check the player is online, then try refreshing this page.</p>
                  <div>
                    <Icon
                      name="refresh"
                      action={()=>this.retry()}
                    />
                  </div>
                </div>
              ) : (
                <Fragment>
                  <Display
                    swapping={ws.swapping}
                    display={venue.display}
                    name={venue.name}
                    hasPlayer={this.props.hasPlayer && ws.connected}
                    fullscreen={() => this.fullscreen()}
                    energy={this.props.energy}
                    downloading={this.props.downloading}
                  />
                  <Controls
                    hasPlayer={this.props.hasPlayer && ws.connected}
                    isPlaying={this.props.isPlaying}
                    downloading={this.props.downloading}
                    swapping={ws.swapping}
                  />
                </Fragment>
              )}
            </div>
            <LastPlayed
              data={this.props.lastPlayed}
              likeTrack={(track)=>this.likeTrack(track)}
              blockTrack={(track)=>this.blockTrack(track)}
              blockedTracks={this.props.blockedTracks}
              blockedTracksLoading={this.props.blockedTracksLoading}
            />
          </Container>
          <Container
            classname={`venue-controls-wrapper venue-energy-slider ${isDisconnected ? 'venue-controls-wrapper--disabled' : ''}`}
            height="100%"
            column
          >
            <Energy/>
          </Container>
          <Confirm />
        </Container>
      )
    }
  }
}
function mapStateToProps(store, ownProps){
  return {
    id:ownProps.match.params.id,
    venue:store.venueWS.data,
    lastPlayed:store.venueWS.lastPlayed,
    ws:store.venueWS.ws,
    loading:store.venueWS.loading,
    loaded:store.venueWS.loaded,
    isFullscreen:store.venueWS.isFullscreen,
    downloading:store.venueWS.downloading,
    hasPlayer:store.venueWS.hasPlayer,
    energy:store.venueWS.energy,
    blockedTracks: store.venue.blockedTracks,
    blockedTracksLoading: store.venue.blockedTracksLoading
  }
}
export default withRouter(connect(mapStateToProps)(VenueManager))
