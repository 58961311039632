const initialState = {
  tracks:[],
  typeahead:[],
  genres:[],
  count:null,
  loading:false,
  trackLoading:false,
  track:null,
  typeaheadLoading:false,
  loaded:false,
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'CLEAR_TRACKS':{
      state = {...state,tracks:[]}
      break
    }
    case 'FETCH_TRACKS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_TRACKS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_TRACKS_FULFILLED':{
      const tracks = action.payload.data.tracks.map((track)=>{
        const data = {
          id:track.id,
          title:track.title,
          artist:track.artist,
          album:track.album,
          genres:track.genres,
          total_length:track.total_length,
          energy:track.energy,
          bpm:track.bpm,
          created_at:track.created_at.standard,
          addedMysql:track.created_at.mysql,
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracks:state.tracks ? state.tracks.concat(tracks) : [],
        count:action.payload.data.count,
        typeahead:[]
      }
      break
    }
    case 'FETCH_TRACK_DETAILS_PENDING':{
      state = {...state,trackLoading:true}
      break
    }
    case 'FETCH_TRACK_DETAILS_REJECTED':{
      state = {...state,trackLoading:false,error:action.payload}
      break
    }
    case 'FETCH_TRACK_DETAILS_FULFILLED':{
      state =  {
        ...state,
        track:action.payload.data,
        trackLoading:false
      }
      break
    }
    case 'GET_GENRES_FULFILLED':{
      state = {...state,genres:action.payload.data}
      break
    }
    case 'SEARCH_TRACKS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'SEARCH_TRACKS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'SEARCH_TRACKS_FULFILLED':{
      let tracks = []
      if(action.payload.data.tracks.length > 0){
        tracks = action.payload.data.tracks.map((track)=>{
          const data = {
            id:track.id,
            title:track.title,
            artist:track.artist,
            album:track.album,
            genres:track.genres,
            total_length:track.total_length,
            energy:track.energy,
            bpm:track.bpm,
            created_at:track.created_at.standard,
          }
          return data
        })
      }
      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracks:state.tracks.concat(tracks),
        count:action.payload.data.count,
        typeahead:[],
        typeaheadLoading:false
      }
      break
    }
    case 'FETCH_TRACK_FULFILLED':{
      let tracks = []
      if(state.tracks){
        tracks = state.tracks.map((track)=>{
          if(track.id === action.meta.trackId){
            track.isPlaying = true
          } else {
            track.isPlaying = false
          }
          return track
        })
      }
      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracks:tracks ? tracks : []
      }
      break
    }
    case 'TYPEAHEAD_TRACKS_PENDING':{
      state = {...state,typeahead:[],typeaheadLoading:true}
      break
    }
    case 'TYPEAHEAD_TRACKS_REJECTED':{
      state = {...state,error:action.payload,typeaheadLoading:false}
      break
    }
    case 'TYPEAHEAD_TRACKS_FULFILLED':{
      state =  {
        ...state,
        typeahead:state.typeahead.concat(action.payload ? action.payload.data : []),
        typeaheadLoading: !action.payload
      }
      break
    }
    case 'UPDATE_TRACK_DETAILS_FULFILLED':{
      const {track_id,data} = action.meta
      const tracks = state.tracks.map(track => {
        if(track.id === track_id){
          return track = {
            ...track,
            title:data.title,
            artist:data.artist,
            album:data.album,
            total_length:data.total_length,
            genres:data.genres
          }
        }
        return track
      })
      state = {
        ...state,
        tracks:tracks,
        loading:false
      }
      break
    }
    case 'UPDATE_TRACKS_DETAILS_FULFILLED':{
      const {
        tracks,
        data
      } = action.meta
      const tracksUpdate = state.tracks.map(track => {
        const foundTrack = tracks.find(findTrack => findTrack.id === track.id)
        if(foundTrack){
          return track = {
            ...track,
            artist:data.artist ? data.artist : track.artist,
            album:data.album ? data.album : track.album,
            genres:data.genres.length > 0 ? data.genres : track.genres
          }
        }
        return track
      })
      state = {
        ...state,
        tracks:tracksUpdate,
        loading:false
      }
      break
    }
    case 'UPDATE_TRACK_ENERGY_FULFILLED':{
      const {track_id,energy} = action.meta
      const tracks = state.tracks.map(track => {
        if(track.id === track_id){
          return track = {
            ...track,
            energy:energy
          }
        }
        return track
      })
      state = {
        ...state,
        tracks:tracks,
        loading:false
      }
      break
    }
    case 'UPDATE_TRACK_GENRES_FULFILLED':{
      const {track_id,genres} = action.meta
      const tracks = state.tracks.map(track => {
        if(track.id === track_id){
          return track = {
            ...track,
            genres:genres
          }
        }
        return track
      })
      state = {
        ...state,
        tracks:tracks,
        loading:false
      }
      break
    }
    case 'CLEAR_TYPEAHEAD_TRACKS':{
      state = {
        ...state,
        typeahead:[]
      }
      break
    }
    case 'DELETE_TRACK_REJECTED':{
      state = {
        ...state,
        error:action.payload
      }
      break
    }
    case 'DELETE_TRACK_FULFILLED':{
      state = {
        ...state,
        tracks: state.tracks.filter(track => {
          return track.id !== action.payload.data.id
        })
      }
      break
    }
    default: // no default case
  }
  return state
}
