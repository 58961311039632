import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getPlaylistTracks,
  filterPlaylistTracks,
  removeTrackFromPlaylist,
  removeTracksFromPlaylist,
  changeDesignation,
  changeDesignations,
  addTrackToQueue,
  removeDuplicates
} from 'store/actions/playlistAction'
import {
  getGenres,
  getTrack
} from 'store/actions/libraryAction'
import { getTrackUrl, getTrackInfo } from 'store/actions/playerAction'
import { toggleOverlay } from 'store/actions/overlayAction'
import { getQueue } from 'helpers/QueueHelper'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import PlaylistTracksTableAction from './playlist-tracks/PlaylistTracksTableAction'
import PlaylistTracksRow from './playlist-tracks/PlaylistTracksRow'

import TableWrapper from 'ui/TableWrapper'
import PlaylistTracksTableRowActions from './playlist-tracks/PlaylistTracksTableRowActions'

import { debounce } from 'helpers/Debounce'
import { tableSorter } from 'helpers/TableHelper'

const classname = 'playlistTracks'

class PlaylistTracks extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:null,
      actionActive:false,
      sorter:{
        field:'added',
        direction:'desc'
      }
    }
    this.filterTracksDispatch = debounce(this.filterTracksDispatch,300)
    this.selectedTracks = []
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistTracks(this.props.id))
    this.props.dispatch(getGenres())
    //clear filter on mount
    this.filterTracksDispatch('')
  }

  componentDidUpdate(prevProps,prevState){
    const {
      tracks,
      filter,
      removeDuplicatesLoading
    } = this.props

    if(prevProps.tracks !== tracks){
      this.setTracks()
    }
    //filter
    if(prevProps.filter !== filter){
      this.setTracks()
    }
    if(prevProps.removeDuplicatesLoading !== removeDuplicatesLoading && !removeDuplicatesLoading){
      this.props.dispatch(getPlaylistTracks(this.props.id))
    }
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track, 'playlist '))
  }

  filterTracks(e){
    const filter = e.target.value
    this.filterTracksDispatch(filter)
  }

  filterTracksDispatch(term){
    this.props.dispatch(filterPlaylistTracks(term))
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'playlistTracksMore'))
  }

  sortTable(head){
    this.setState({
      sorter:head
    },()=>{
      this.setTracks()
    })
  }

  sortType(x,y){
    const {field, direction} = this.state.sorter

    // if no direction is provided, due to the sorting icon being reset,
    // or there is no value provided to be sorted on, use default values
    if (direction === null || !(field in x)) {
      return tableSorter(x,y,'added','desc')
    }

    return tableSorter(x,y,field,direction)
  }

  setTracks(){
    const {
      filter,
      tracks
    } = this.props

    //sort tracks by date added to playlist and filter
    const sortedTracks = tracks
      .sort((x, y) => this.sortType(x, y))
      .filter(track => {
        if (track.filter && track.filter.toLowerCase().includes(filter.toLowerCase())) {
          return track
        }
        return false
      })
    //set tracks in state for use in table
    this.setState({
      tracks:sortedTracks
    })
  }

  removeTrack(data){
    this.props.dispatch(removeTrackFromPlaylist(data))
  }

  checkboxAction(e,data,index){
    if(e.nativeEvent.metaKey || e.nativeEvent.shiftKey){
      return
    }
    if(e.target.checked){
      data.index = index
      this.selectedTracks.push(data)
    } else {
      this.selectedTracks = this.selectedTracks.filter(track => track.id !== data.id)
    }
    if(this.selectedTracks.length > 0){
      this.setState({actionActive:true})
    } else {
      this.setState({actionActive:false})
    }
  }

  clickRow(e,data,isChecked,index){
    e.stopPropagation()
    //cmd + click
    if(e.metaKey){
      if(!isChecked){
        data.index = index
        this.selectedTracks.push(data)
      } else {
        this.selectedTracks = this.selectedTracks.filter(track => track.id !== data.id)
      }
      if(this.selectedTracks.length > 0){
        this.setState({actionActive:true})
      } else {
        this.setState({actionActive:false})
      }
    }
    if(e.shiftKey){
      if(this.selectedTracks.length === 1){
        for (let i = this.selectedTracks[0].index + 1; i <= index; i++) {
          this.selectedTracks.push(this.state.tracks[i])
        }
        this.setState({actionActive:true})
      }
    }
  }

  deselectTracks(){
    this.selectedTracks = []
    this.setState({actionActive:false})
  }

  selectAll(selected){
    const {
      filter,
      tracks
    } = this.props

    // create new array, so that we do not mutate the props data
    // allows proper reset of sorting order
    const sortedTracks = [...tracks]

    //sort tracks by date added to playlist and filter
    if(selected){
      sortedTracks
        .sort((x, y) => this.sortType(x, y))
        .filter(track => {
          //console.log(track.filter)
          if (track.filter && track.filter.toLowerCase().includes(filter.toLowerCase())) {
            this.selectedTracks.push(track)
          }
          return false
        })
        this.setState({actionActive:true})
    } else {
      this.selectedTracks = []
      this.setState({actionActive:false})
    }
    
  }


  getBreakdown(data){
    this.props.dispatch(toggleOverlay(true,data,'breakdown'))
  }

  removeTracks(){
    this.props.dispatch(removeTracksFromPlaylist(this.selectedTracks));
    this.setState({
      actionActive:false
    })
    this.deselectTracks()
  }

  addTracksToPlaylist(){
    this.props.dispatch(toggleOverlay(true,this.selectedTracks,'multiPlaylistsAdd'))
  }

  editTracks(){
    this.props.dispatch(toggleOverlay(true,this.selectedTracks,'multiEditTracks'))
  }

  openPlaylistOverlay(track){
    this.props.dispatch(toggleOverlay(true,{track, playlistID: this.props.id},'playlistsAdd'))
  }

  changeDesignation(data){
    //get new split id
    const newSplitID = this.props.metadata[data.event.target.value].playlist_id
    const track = data.data
    const send = {
      track_id:track.id,
      curr_split_id:track.split_id,
      parent_id:track.parent_id,
      new_split_id:newSplitID,
      designation:data.event.target.value
    }
    this.props.dispatch(changeDesignation(send))
  }

  changeDesignations(data){
    //get new split id
    const newSplitID = this.props.metadata[data.event.target.value].playlist_id
    this.props.dispatch(changeDesignations(this.selectedTracks.map(track => {
      return {
        track_id:track.id,
        curr_split_id:track.split_id,
        parent_id:track.parent_id,
        new_split_id:newSplitID,
        designation:data.event.target.value
      }
    })))
    this.deselectTracks()
  }

  addTrackToQueue(data){
    this.props.dispatch(addTrackToQueue(data.track))
    getQueue()
  }

  favouriteTrack(data){
    console.log(data)
  }

  editTrack(track){
    this.props.dispatch(getTrack(track.id))
    this.props.dispatch(toggleOverlay(true,null,'editTrack'))
  }

  libraryAction(data,e){
    if(e && e.metaKey){
      e.stopPropagation()
      window.open(data,'_blank')
    } else {
      this.props.history.push(`/library/${data}`)
    }
  }

  removeDuplicates(){
    this.props.dispatch(removeDuplicates(this.props.id))
  }

  getTable(){
    return <TableWrapper
      classname={classname}
      data={this.state.tracks}
      loading={this.props.loading}
      sortTable={(head)=>this.sortTable(head)}
      selectAll={(selected)=>this.selectAll(selected)}
      rowComp={
        <PlaylistTracksRow
          play={(track)=>this.playTrack(track)}
          playingTrack={this.props.playingTrack}
          checkboxSelected={this.selectedTracks}
          checkboxAction={(e,data,index)=>this.checkboxAction(e,data,index)}
          clickRow={(e,data,isChecked,index)=>this.clickRow(e,data,isChecked,index)}
          libraryAction={(data,e) => this.libraryAction(data,e)}
          />
      }
      rowActions={
        <PlaylistTracksTableRowActions
          favouriteTrack={(data)=>this.favouriteTrack(data)}
          moreActions={(data)=>this.moreActions(data)}
          removeTrack={(data)=>this.removeTrack(data)}
          openPlaylistOverlay={(data)=>this.openPlaylistOverlay(data)}
          changeDesignation={(data)=>{this.changeDesignation(data)}}
          addTrackToQueue={(data)=>{this.addTrackToQueue(data)}}
          editTrack={(track) => this.editTrack(track)}
          classname={classname}
        />
      }
      tableAction={<PlaylistTracksTableAction
        removeDuplicates={()=>this.removeDuplicates()}
        active={this.state.actionActive}
        classname={classname}
        metadata={this.props.metadata}
        count={this.props.tracks.length}
        placeholder="Filter tracks..."
        numSelected={this.selectedTracks.length}
        action={(e)=>this.filterTracks(e)}
        changeInfo={()=>this.editTracks()}
        addTrackToQueue={(data)=>this.addTrackToQueue(data)}
        deselectTracks={()=>this.deselectTracks()}
        removeTracks={()=>this.removeTracks()}
        addTracksToPlaylist={()=>this.addTracksToPlaylist()}
        getBreakdown={(data)=>this.getBreakdown(data)}
        openDesignation={(designation,id)=>this.props.openDesignation(designation,id)}
        CSVData={this.getCSVData()}
        CSVFileName={`${this.props.playlistName}--tracks`}
        changeDesignations={(split)=>this.changeDesignations(split)}/>
      }
      />
  }

  getCSVData() {
    const {
      tracks
    } = this.props

    let csvDataArray = []

    tracks.map((track) => {
      // escape double quotes in the csv file
      const trackData = {
        artist: track.artist ? track.artist.replace(/"/g, '""') : 'MISSING ARTIST INFO',
        title: track.title ? track.title.replace(/"/g, '""') : 'MISSING TRACK INFO'
      }

      csvDataArray.push(trackData)
      return false
    })

    return csvDataArray
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-tracks" height="100%" column>
          {this.getTable()}
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    tracks:store.playlist.tracks,
    metadata:store.playlist.metadata,
    filter:store.playlist.filter,
    loading:store.playlist.loading,
    playingTrack: store.player.track,
    removeDuplicatesLoading:store.playlist.removeDuplicatesLoading
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistTracks))
