const initialState = {
  details:{
    name:null,
    contactName:null,
    contactEmail:null,
    contactNumber:null,
    url:null,
    username:null,
    status:null,
    statuses:[]
  },
  venues:[],
  report: {},
  reportPlaylistData: [],
  playlists:[],
  totalTracks:0,
  loading:false,
  loaded:false,
  error:null,
  library: null,
  settings:{
    importer:null,
    playback:null
  },
  settingsLoading:false
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'CLEAR_BUSINESS':{
      state = {...state,details:{},venues:[]}
      break
    }
    case 'FETCH_BUSINESS_DETAILS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_BUSINESS_DETAILS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_DETAILS_FULFILLED':{
      const data = action.payload.data.business
      state = {
        ...state,
        loading:false,
        details:{
          name:data.name,
          contactName:data.contact_name,
          contactEmail:data.contact_email,
          contactNumber:data.contact_number,
          url:data.url,
          username:data.users[0].username,
          status:data.status.id,
          statuses:data.statuses.map(status => {
            return {value:status.id,display:status.status}
          }),
          setup:data.setup
        }
      }
      break
    }
    case 'UPDATE_BUSINESS_DETAILS_PENDING':{
      state = {
        ...state,
        loading:true,
      }
      break
    }
    case 'UPDATE_BUSINESS_DETAILS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'UPDATE_BUSINESS_DETAILS_FULFILLED':{
      const data = action.payload.data
      state = {
        ...state,
        loading:false,
        details:{
          ...state.details,
          name:data.name,
          contactName:data.contact_name,
          contactEmail:data.contact_email,
          contactNumber:data.contact_number,
          url:data.url || '',
          username:data.users[0].username,
          status:data.status.id,
          setup:data.setup
        }
      }
      break
    }
    case 'FETCH_BUSINESS_USER_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_BUSINESS_USER_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_USER_FULFILLED':{
      const {data} = action.payload

      state =  {
        ...state,
        loading:false,
        loaded:true,
        user:{
          ...state.user,
          id:data.id,
          name:data.name,
          username:data.users[0].username,
          loginHash:data.users[0].login_hash
        }
      }
      break
    }
    case 'UPDATE_BUSINESS_USER_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'UPDATE_BUSINESS_USER_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'UPDATE_BUSINESS_USER_FULFILLED':{
      const {data} = action.payload

      state =  {
        ...state,
        error:null,
        loading:false,
        loaded:true,
        user:{
          ...state.user,
          id:data.id,
          name:data.name,
          username:data.users[0].username,
          loginHash:data.users[0].login_hash
        }
      }
      break
    }
    case 'FETCH_BUSINESS_VENUES_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_BUSINESS_VENUES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_VENUES_FULFILLED':{
      const venues = action.payload.data.venues.map((venue)=>{
        const data = {
          id:venue.id,
          name:venue.name,
          venue_user:venue.user[0] ? venue.user[0].username : '',
          playlists_count:venue.playlists.length,
          total_tracks:venue.total_tracks,
          blocked_count:venue.blocked_count,
          created_at:venue.created_at.standard,
          last_login:venue.user[0] ? venue.user[0].last_login.standard : '',
          authToken:venue.authToken,
          online:false
        }
        return data
      })
      state = {
        ...state,
        venues:venues,
        loading:false,
        loaded:true
      }
      break
    }
    case 'FETCH_BUSINESS_GENRES_BREAKDOWN_PENDING':{
      state = {...state,loading:true, loaded: false}
      break
    }
    case 'FETCH_BUSINESS_GENRES_BREAKDOWN_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_GENRES_BREAKDOWN_FULFILLED':{
      state = {
        ...state,
        report: action.payload.data,
        loading:false
      }
      break
    }
    case 'FETCH_BUSINESS_TRACKS_BREAKDOWN_PENDING':{
      state = {...state,loading:true, loaded: false}
      break
    }
    case 'FETCH_BUSINESS_TRACKS_BREAKDOWN_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_TRACKS_BREAKDOWN_FULFILLED':{
      let reportPlaylistData = {}
      delete action.payload.data.exists

      console.log("action.payload.data", action.payload.data, Object.entries(action.payload.data))

        reportPlaylistData = Object.entries(action.payload.data).map((playlistData) => {
          const date = new Date(playlistData[0]);  // 2009-11-10
          const month = date.toLocaleString('default', { month: 'short' });
          const data = {
            name:month,
            Existing: playlistData[1].total,
            New:playlistData[1].new
          }

          return data
        })

      state = {
        ...state,
        reportPlaylistData: reportPlaylistData,
        loading:false
      }
      break
    }
    case 'FETCH_BUSINESS_LIBRARY_PENDING':{
      state = {...state,loading:true, loaded: false}
      break
    }
    case 'FETCH_BUSINESS_LIBRARY_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_LIBRARY_FULFILLED':{
      state = {
        ...state,
        library: action.payload.data,
        loading:false
      }
      break
    }
    case 'FETCH_BUSINESS_PLAYLISTS_PENDING':{
      state = {...state, loading:true, playlists:[]}
      break
    }
    case 'FETCH_BUSINESS_PLAYLISTS_REJECTED':{
      state = {...state, loading:false, error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_PLAYLISTS_FULFILLED':{
      let totalTracks = 0
      const playlists = action.payload.data.map((playlist) => {
        const data = {
          id: playlist.id,
          name: playlist.name,
          tracksCount: playlist.tracks_count,
          type: playlist.playlist_types.type,
          createdAt: playlist.created_at
        }
        totalTracks += playlist.tracks_count
        return data
      })
      state =  {
        ...state,
        loading: false,
        loaded: true,
        playlists: playlists,
        totalTracks: totalTracks,
      }
      break
    }
    case 'ADD_PLAYLIST_TO_BUSINESS_FULFILLED':{
      state = {
        ...state,
        playlists:state.playlists.concat(action.meta)
      }
      break
    }
    case 'REMOVE_PLAYLIST_FROM_BUSINESS_FULFILLED':{
      state = {
        ...state,
        playlists:state.playlists.filter(playlist => playlist.id !== action.meta.id)
      }
      break
    }
    case 'DELETE_INTERLEAVES_FROM_BUSINESS_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }
    case 'DELETE_INTERLEAVES_FROM_BUSINESS_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }
    case 'DELETE_INTERLEAVES_FROM_BUSINESS_FULFILLED':{
      state = {
        ...state,
        loading: false
      }
      break
    }
    case 'FETCH_BUSINESS_SETTINGS_PENDING':{
      state = {
        ...state,
        settingsLoading: true,
        settings:{}
      }
      break
    }
    case 'FETCH_BUSINESS_SETTINGS_REJECTED':{
      state = {
        ...state,
        settingsLoading: false
      }
      break
    }
    case 'FETCH_BUSINESS_SETTINGS_FULFILLED':{
      state = {
        ...state,
        settingsLoading: false,
        settings:action.payload.data
      }
      break
    }
    //check player online
    case 'WS_PLAYER_ONLINE':{
      
      const venues = state.venues.map(venue => {
        const id = parseInt(action.payload.vid)
        if(venue.id === id){
          venue.online = action.payload.online
          venue.onlineChecked = true
        }
        return venue
      })
      state =  {
        ...state,
        venues:venues,
        loading:false,
        loaded:true
      }
      break
    }
    default: // no default
  }
  return state
}
