import axios from 'axios'
import { functions } from "lodash"
const CancelToken = axios.CancelToken
let cancel

export function getPlaylists(type,limit,offset,sort = null){

  const headers = {
    'Limit':limit,
    'Offset':offset
  }

  if(sort && sort.direction){
    headers['Sort'] = true
    headers['Field'] = sort.field
    headers['Direction'] = sort.direction
  }

  const urlMap = {
    owned:'playlists/owner',
    pinned:'playlists/pinned',
    recent:'playlists/recent',
    admin:'playlists/admin',
    special:'playlists/special',
    child:'playlists/child',
  }

  return (dispatch) => {
    cancel && cancel('FETCH_PLAYLISTS request cancelled')
    const request = axios.get(
      urlMap[type],
      {
        headers: headers,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        })
      }
    ).catch((cancel) => {
      console.log(cancel)
      return 'cancelled'
    })

    return dispatch({
      type:'FETCH_PLAYLISTS',
      payload: request
    })
  }
}

export function getPlaylistsOverlay(type,limit,offset,trackID){

  const headers = {
    'Limit':limit,
    'Offset':offset
  }
  const urlMap = {
    assigned:`playlists/overlay/assigned/${trackID}`,
    owned:`playlists/overlay/owner/${trackID}`,
    pinned:`playlists/overlay/pinned/${trackID}`,
    recent:`playlists/overlay/recent/${trackID}`,
  }
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLISTS',
      payload:axios.get(urlMap[type],{headers:headers})
    })
  }
}

export function getPlaylistsOverlayMulti(type,limit,offset){

  const headers = {
    'Limit':limit,
    'Offset':offset
  }
  const urlMap = {
    owned:`playlists/overlay/multi/owner`,
    pinned:`playlists/overlay/multi/pinned`,
    recent:`playlists/overlay/multi/recent`,
  }
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLISTS',
      payload:axios.get(urlMap[type],{headers:headers})
    })
  }
}

export function clearPlaylists(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_PLAYLISTS',
      payload:null
    })
  }
}

export function clearPlaylist(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_PLAYLIST',
      payload:null
    })
  }
}

export function clearPlaylistTypeahead(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_PLAYLIST_TYPEAHEAD',
      payload:null
    })
  }
}

export function getPlaylistDetails(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_DETAILS',
      payload:axios.get(`playlist/details/${id}`)
    })
  }
}

export function updatePlaylistDetails(id,data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_PLAYLIST_DETAILS',
      payload:axios.post(`playlist/details/${id}`, data)
    })
  }
}

export function getPlaylistTracks(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_TRACKS',
      payload:axios.get(`playlist/tracks/${id}`)
    })
  }
}

export function getPlaylistTracksSplit(id,designation){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_TRACKS_SPLIT',
      payload:axios.get(`playlist/tracks/split/${id}`),
      meta:designation
    })
  }
}

export function getPlaylistTracksForApproval(id,designation){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_TRACKS_FOR_APPROVAL',
      payload: axios.get(`playlist/tracks/pending/${id}`)
    })
  }
}

export function approveChange(data){
  return (dispatch) => {
    return dispatch({
      type:'APPROVE_CHANGE',
      payload: axios.post(`playlist/tracks/pending`, data),
      meta: data.trackID
    })
  }
}

export function rejectChange(id){
  return (dispatch) => {
    return dispatch({
      type:'REJECT_CHANGE',
      payload: axios.delete(`playlist/tracks/pending/${id}`),
      meta: id
    })
  }
}

export function getPlaylistOrdered(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_TRACKS_ORDERED',
      payload:axios.get(`playlist/ordered/${id}`)
    })
  }
}

export function orderPlaylist(newIndex,track){
  return (dispatch) => {
    return dispatch({
      type:'ORDER_PLAYLIST_TRACKS',
      payload:{newIndex:newIndex,track:track},
      meta:track
    })
  }
}

export function updatePlaylistOrder(tracks,playlistID){
  return (dispatch) => {
    const data = tracks.map(track => {
      const data = {trackID:track.id,index:track.index}
      return data
    })
    return dispatch({
      type:'UPDATE_PLAYLIST_ORDER',
      payload:axios.post(`playlist/order/${playlistID}`,data)
    })
  }
}

export function getPlaylistVenues(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_VENUES',
      payload:axios.get(`playlist/venues/${id}`)
    })
  }
}

export function getPlaylistChildren(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_CHILDREN',
      payload:axios.get(`playlist/children/${id}`)
    })
  }
}

export function getPlaylistCurators(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_CURATORS',
      payload:axios.get(`playlist/curators/${id}`)
    })
  }
}

export function removePlaylistCurator(curator_id,playlist_id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_PLAYLIST_CURATOR',
      payload:axios.delete(`playlist/curator`,{
        data:{
          playlist_id:playlist_id,
          curator_id:curator_id
        }
      }),
      meta:curator_id
    })
  }
}

export function addPlaylistCurator(curator,playlist_id){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_CURATOR',
      payload:axios.post(`playlist/curator`,{
        playlist_id:playlist_id,
        curator_id:curator.id
      }),
      meta:curator
    })
  }
}

export function getPlaylistImage(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_IMAGE',
      payload:axios.get(`playlist/image/${id}`)
    })
  }
}

export function deletePlaylistImage(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_PLAYLIST_IMAGE',
      payload:axios.delete(`playlist/image/${id}`)
    })
  }
}

export function imageUploading(){
  return (dispatch) => {
    return dispatch({
      type:'PLAYLIST_IMAGE_LOADING',
      payload:true
    })
  }
}

export function createPlaylistImage(data){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_PLAYLIST_IMAGE',
      payload:axios.post(`playlist/image`,data)
    })
  }
}

export function pinPlaylist(id){
  return (dispatch) => {
    return dispatch({
      type:'PIN_PLAYLIST',
      payload:axios.put(`playlists/pin/${id}`),
      meta:id
    })
  }
}
export function unpinPlaylist(playlist){
  return (dispatch) => {
    return dispatch({
      type:'UNPIN_PLAYLIST',
      payload:axios.delete(`playlists/pin/${playlist.pinned_id}`),
      meta:playlist.id
    })
  }
}
export function filterPlaylistTracks(term){
  return (dispatch) => {
    return dispatch({
      type:'FILTER_PLAYLIST_TRACKS',
      payload:term
    })
  }
}

export function addTrackToPlaylist(track,playlist){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_TRACK',
      payload:axios.put(`playlist/track/${track.id}/${playlist.id}`),
      meta:playlist
    })
  }
}

export function addTrackToPlaylistLibrary(track_id,playlist_id){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_TRACK_LIBRARY',
      payload:axios.put(`playlist/track/${track_id}/${playlist_id}`),
      meta:playlist_id
    })
  }
}

export function addTracksToPlaylist(playlist_id, track_ids){
  return (dispatch) => {
    return dispatch({
      type:'ADD_TRACKS_TO_PLAYLIST',
      payload:axios.post(`playlist/tracks/${playlist_id}`, track_ids),
      meta:playlist_id
    })
  }
}

export function addTrackToPlaylistLibrarySearch(track_id,playlist_id){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_TRACK_LIBRARY_SEARCH',
      payload:axios.put(`playlist/track/${track_id}/${playlist_id}`),
      meta:playlist_id
    })
  }
}

export function addTrackToPlaylistOwned(track,playlist){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_TRACK_OWNED',
      payload:axios.put(`playlist/track/${track.id}/${playlist.id}`),
      meta:{
        track:track,
        playlist:playlist
      }
    })
  }
}

export function removeTrackFromPlaylistOwned(track){
  return (dispatch) => {
    const data = {
      track_id:track.id,
      split_id:track.split_id ? track.split_id : null,
      parent_id:track.parent_id ? track.parent_id : null,
      designation:track.designation ? track.designation : null
    }
    return dispatch({
      type:'DELETE_PLAYLIST_TRACK_OWNED',
      payload:axios.delete(`playlist/track`,{data}),
      meta:track.id
    })
  }
}

export function removeTrackFromPlaylist(track){
  return (dispatch) => {
    const data = {
      track_id:track.id,
      split_id:track.split_id ? track.split_id : null,
      parent_id:track.parent_id ? track.parent_id : null,
      designation:track.designation ? track.designation : null
    }
    return dispatch({
      type:'DELETE_PLAYLIST_TRACK',
      payload:axios.delete(`playlist/track`,{data}),
      meta:track
    })
  }
}

export function removeTracksFromPlaylist(tracks){
  return (dispatch) => {
    let data = []
    for(let track of tracks){
      data.push({
        track_id:track.id,
        split_id:track.split_id ? track.split_id : null,
        parent_id:track.parent_id ? track.parent_id : null,
        designation:track.designation ? track.designation : null
      })
    }
    return dispatch({
      type:'DELETE_PLAYLIST_TRACKS',
      payload:axios.delete(`playlist/tracks`,{data}),
      meta:tracks
    })
  }
}



export function removeTrackFromPlaylistLibrary(playlist,track_id){
  return (dispatch) => {
    const data = {
      track_id:track_id,
      split_id:playlist.id ? playlist.id : null,
      designation:playlist.designation ? playlist.designation : null
    }
    return dispatch({
      type:'DELETE_PLAYLIST_TRACK_LIBRARY',
      payload:axios.delete(`playlist/track`,{data}),
      meta:playlist.id
    })
  }
}

export function removeTrackFromPlaylistLibrarySearch(playlist,track_id){
  return (dispatch) => {
    const data = {
      track_id:track_id,
      split_id:playlist.id ? playlist.id : null,
      designation:playlist.designation ? playlist.designation : null
    }
    return dispatch({
      type:'DELETE_PLAYLIST_TRACK_LIBRARY_SEARCH',
      payload:axios.delete(`playlist/track`,{data}),
      meta:playlist.id
    })
  }
}

export function removeVenueFromPlaylist(venueID,playlistID){
  return (dispatch) => {
    return dispatch({
      type:'REMOVE_VENUE_PLAYLIST',
      payload:axios.delete(`playlist/venue/${venueID}/${playlistID}`),
      meta:venueID
    })
  }
}

export function playlistTypeaheadAll(term){
  return (dispatch) => {
    cancel && cancel('PLAYLISTS_TYPEAHEAD_ALL request cancelled')
    const request = axios.get(
      `playlists/typeahead/all/${term}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        })
      }
    ).catch((cancel) => {
      console.log(cancel)
      return 'cancelled'
    })

    return dispatch({
      type:'PLAYLISTS_TYPEAHEAD_ALL',
      payload: request
    })
  }
}

export function overlayFitnessPlaylistTypeaheadAll(term){
  return (dispatch) => {
    return dispatch({
      type:'OVERLAY_FITNESS_PLAYLISTS_TYPEAHEAD_ALL',
      payload:axios.post(`playlists/typeahead/all`,{term:term})
    })
  }
}

export function playlistOverlaySearch(term,track_id){
  return (dispatch) => {
    return dispatch({
      type:'PLAYLISTS_OVERLAY_SEARCH',
      payload:axios.post(
        `playlists/overlay/search`,
        {
          term:term,
          track_id:track_id
        }
      )
    })
  }
}

export function playlistOverlaySearchMulti(term){
  return (dispatch) => {
    return dispatch({
      type:'PLAYLISTS_OVERLAY_SEARCH',
      payload:axios.post(
        `playlists/overlay/multi/search`,
        {
          term:term
        }
      )
    })
  }
}

export function playlistTypeaheadOwned(term){
  return (dispatch) => {
    return dispatch({
      type:'PLAYLISTS_TYPEAHEAD_ALL',
      payload:axios.post(`playlists/typeahead/owned`,{term:term})
    })
  }
}

export function subscribePlaylist(playlistID){
  return (dispatch) => {
    return dispatch({
      type:'PUT_SUBSCRIBE_PLAYLIST',
      payload:axios.put(`playlists/subscribe/${playlistID}`),
      meta:playlistID
    })
  }
}

export function changeDesignation(data){
  return (dispatch) => {
    return dispatch({
      type:'PUT_CHANGE_DESIGNATION',
      payload:axios.put(`playlist/tracks/designation/${data.track_id}`,data),
      meta:{
        track_id:data.track_id,
        split_id:data.new_split_id,
        designation:data.designation
      }
    })
  }
}

export function changeDesignations(data){
  return (dispatch) => {
    return dispatch({
      type:'PUT_CHANGE_DESIGNATION_MULTI',
      payload:axios.post(`playlist/tracks/designation/multi`,data),
      meta:data
    })
  }
}

export function getPlaylistTypes(){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_TYPES',
      payload:axios.get('playlists/types')
    })
  }
}

export function createPlaylist(data){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_PLAYLIST',
      payload:axios.post('playlist/create',data)
    })
  }
}

export function createPlaylistAddVenues(data, playlistID){
  const formData = {
    ...data,
    id: playlistID
  }
  return (dispatch) => {
    return dispatch({
      type:'CREATE_PLAYLIST_ADD_VENUES',
      payload:axios.post('playlist/create/venues',formData)
    })
  }
}

export function resetCreatePlaylist(){
  return (dispatch) => {
    return dispatch({
      type:'RESET_CREATE_PLAYLIST'
    })
  }
}

export function searchPlaylists(term,limit,offset){
  const headers = {
    'Limit':limit,
    'Offset':offset
  }
  return (dispatch) => {
    cancel && cancel('PLAYLISTS_TYPEAHEAD_ALL request cancelled by SEARCH_VENUES')
    return dispatch({
      type:'SEARCH_PLAYLISTS',
      payload:axios.get(`playlists/search/${term}`,{headers:headers})
    })
  }
}

export function addTrackToQueue(track){
  return (dispatch) => {
    return dispatch({
      type:'ADD_TRACK_TO_QUEUE',
      payload:track
    })
  }
}

export function getImporterPlaylists(){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_IMPORTER_PLAYLISTS',
      payload:axios.get('playlists/importer/playlists')
    })
  }
}

export function getImporterPlaylist(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_IMPORTER_PLAYLIST',
      payload:axios.get(`playlists/importer/playlist/${id}`)
    })
  }
}

export function getImporterMatches(data){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_IMPORTER_MATCHES',
      payload:axios.post('playlists/importer/match', data),
      meta: data
    })
  }
}

export function addToFinalTracklist(track){
  return (dispatch) => {
    return dispatch({
      type:'ADD_TO_FINAL_TRACKLIST',
      payload: null,
      meta: track
    })
  }
}

export function removeFromFinalTracklist(track){
  return (dispatch) => {
    return dispatch({
      type:'REMOVE_FROM_FINAL_TRACKLIST',
      payload: null,
      meta: track
    })
  }
}

export function createImporterPlaylist(data){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_IMPORTER_PLAYLIST',
      payload:axios.post('playlists/importer/create',data)
    })
  }
}

export function getPlaylistTracksToImport(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_TRACKS_TO_IMPORT',
      payload:axios.get(`playlist/importer/tracks/${id}`)
    })
  }
}

export function deletePlaylistTrackToImport(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_PLAYLIST_TRACKS_TO_IMPORT',
      payload: axios.delete(`playlist/importer/track/${id}`),
      meta: id
    })
  }
}

export function clearOverlayVenues(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_OVERLAY_VENUES',
      payload:null
    })
  }
}

export function getPlaylistAddToVenuesSearch(limit,offset,term,playlist,initialLoad){
  const headers = {
    'Limit':limit,
    'Offset':offset
  }

  return (dispatch) => {
    cancel && cancel('FETCH_PLAYLIST_ADD_TO_VENUES_SEARCH request cancelled')
    const request = axios.get(
      `/playlists/overlay/venues/search/${playlist}/${term}`,
      {
        headers: headers,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        })
      }
    ).catch((cancel) => {
      console.log(cancel)
      return 'cancelled'
    })

    return dispatch({
      type:'PLAYLIST_ADD_TO_VENUES_SEARCH',
      payload: request,
      meta: initialLoad
    })
  }
}
export function splitPlaylist(id){
  return (dispatch) => {
    return dispatch({
      type:'SPLIT_PLAYLIST',
      payload:axios.put(`playlists/actions/split/${id}`),
      meta:id
    })
  }
}

export function copyPlaylist(id,settings,name){
  return (dispatch) => {
    return dispatch({
      type:'COPY_PLAYLIST',
      payload:axios.post(`playlists/actions/copy/${id}`,{
        settings:settings,
        name:name
      }),
    })
  }
}

export function mergePlaylist(id){
  return (dispatch) => {
    return dispatch({
      type:'MERGE_PLAYLIST',
      payload:axios.put(`playlists/actions/merge/${id}`),
      meta:id
    })
  }
}

export function mergePlaylists(name,playlists){
  return (dispatch) => {
    return dispatch({
      type:'MERGE_PLAYLISTS',
      payload:axios.post(`playlists/actions/merge`,{
        playlists:playlists,
        name:name
      })
    })
  }
}

export function deletePlaylist(playlist_id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_PLAYLIST',
      payload:axios.delete(`playlists/actions/delete/${playlist_id}`),
      meta:playlist_id
    })
  }
}

export function getPlaylistSettings(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_SETTINGS',
      payload:axios.get(`playlists/settings/${id}`)
    })
  }
}

export function getParentPlaylistTypeahead(term){
  return (dispatch) => {
    return dispatch({
      type:'OVERLAY_PARENT_PLAYLISTS_TYPEAHEAD',
      payload:axios.get(`playlists/settings/parent/typeahead/${term}`)
    })
  }
}

export function getOwnerTypeahead(term){
  return (dispatch) => {
    return dispatch({
      type:'SETTINGS_OWNER_TYPEAHEAD',
      payload:axios.get(`playlists/settings/owners/typeahead/${term}`)
    })
  }
}

export function updateParent(data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_PARENT_PLAYLIST',
      payload:axios.put('playlists/settings/update-parent',{
        playlist_id:data.playlist.id,
        parent_id:data.parent.id
      }),
      meta:data.parent
    })
  }
}

export function updateOwner(playlist_id,owner){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_OWNER_PLAYLIST',
      payload:axios.put('playlists/settings/update-owner',{
        playlist_id:playlist_id,
        owner_id:owner.id
      }),
      meta:owner
    })
  }
}

export function updatePlaylistType(playlist_id,type_id){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_PLAYLIST_TYPE',
      payload:axios.put('playlists/settings/type',{
        playlist_id:playlist_id,
        type_id:type_id
      }),
      meta:type_id
    })
  }
}

export function updateRotation(playlist_id,rotation){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_PLAYLIST_ROTATION',
      payload:axios.put(`playlists/settings/rotation`,{
        playlist_id:playlist_id,
        rotation:rotation
      })
    })
  }
}
export function updateAutoAdd(playlist_id,auto_add){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_PLAYLIST_AUTO_ADD',
      payload:axios.put(`playlists/settings/auto-add`,{
        playlist_id:playlist_id,
        auto_add:auto_add
      })
    })
  }
}
export function updateAutoDelete(playlist_id,auto_delete){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_PLAYLIST_AUTO_DELETE',
      payload:axios.put(`playlists/settings/auto-delete`,{
        playlist_id:playlist_id,
        auto_delete:auto_delete
      })
    })
  }
}
export function removeDuplicates(playlist_id){
  return (dispatch) => {
    return dispatch({
      type:'REMOVE_DUPLICATES',
      payload:axios.put(`playlist/tracks/remove-duplicates`,{
        playlist_id:playlist_id
      })
    })
  }
}