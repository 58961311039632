import React, {Component} from 'react'
import { connect } from 'react-redux'

import { getPlaylistChildren } from 'store/actions/playlistAction'
import { getTrackUrl } from 'store/actions/playerAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'
import PlaylistChildrenTableRowActions from './playlist-children/PlaylistChildrenTableRowActions'
import { tableSorter } from 'helpers/TableHelper'

const classname = 'playlistChildren'

class PlaylistChildren extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:'',
      sorter:{
        field:'added',
        direction:'desc'
      }
    }
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistChildren(this.props.id))
  }

  playTrack(id){
    this.props.dispatch(getTrackUrl(id))
  }

  removeVenue(id){
    console.log(id)
  }

  filterVenues(e){
    const text = e.target.value
    this.setState({filter:text})
  }

  sortTable(head){
    console.log(head)
    this.setState({
      sorter:head
    })
  }

  sortType(x,y){
    const {field, direction} = this.state.sorter
    return tableSorter(x,y,field,direction)
  }

  getPlaylistChildren(){
    //sort by date added to playlist and filter
    return this.props.playlistChildren
      .sort((x,y) => this.sortType(x,y))
      .filter(playlist => {
        if(playlist.name.toLowerCase().includes(this.state.filter)){
          return playlist
        }
        return false
      })
  }

  viewPlaylist(id){
    this.props.history.push(`/playlists/${id}?tab=tracks`)
  }

  getTable(){
    return <TableWrapper
      classname={classname}
      data={this.getPlaylistChildren()}
      loading={this.props.loading}
      sortTable={(head)=>this.sortTable(head)}
      rowActions={<PlaylistChildrenTableRowActions
        viewPlaylist={(id) => this.viewPlaylist(id)}
        classname={classname}/>
      }/>
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container height="100%">
          {this.getTable()}
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    playlistChildren:store.playlist.children,
    loading:store.playlist.loading
  }
}

export default connect(mapStateToProps)(PlaylistChildren)
